import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

export default (to , from , next) => {
    // console.log(to);
    if(process.env.VUE_APP_TOKEN === to.query.token){
        next();
    }else{
        toaster.error(`You don't have permission to view commericial screen data` , {position: 'top'});
        next({name: from.name});
    }
}