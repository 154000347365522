<template>
    <div class="admin">
        <h3 class="text-white">Hello, {{ financeName }}</h3>
    </div>
</template>

<script>
    export default {
        name: 'home-admin',
        mounted(){
            this.$store.dispatch('activeTap', 'home');
            this.financeName = this.$store.state.auth.user.user.name;
        },
        data(){
            return {
                financeName:null
            }
        },
    }
</script>

<style scoped>

</style>