<template>
  <div class="create-market">
    <form action="">
      <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
          <h3>Edit Market</h3>
        </div>
        <div class="">
          <div class="d-flex align-items-end">
            <button
              @click.prevent="updateMarket"
              class="create-button"
              :class="[{ notAllowed: errorBtnSave }]"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="form-body">
        <div class="input-container mb-2">
          <label class="mb-2">Market Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Market Name"
            v-model="market.name"
            @input="checkName"
          />
          <span class="text-danger" v-if="errorName">*Required</span>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.allowed {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.form-body {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  /* display: flex; */
  /* flex-wrap: wrap;
        justify-content: space-around; */
  padding: 1rem;
  overflow: auto;
  color: #fff;
}
.form-body .input-container {
  width: 40%;
}
.form-body .input-container label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
.form-select {
  background-color: #3C3C3B;
  position: relative;
  filter: invert(100%);
  color: white;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 14px;
  padding: 0.5em 1em;
}
.form-control {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 25px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";
import market from "../../api-services/market";
export default {
  name: "edit-market",
  mounted() {
    this.getMarket();
    this.$store.dispatch("activeTap", "markets");
  },
  data() {
    return {
      market: {
        name: null,
      },
      errorName: false,
      errorBtnSave: false,
    };
  },
  methods: {
    checkErrors() {
      this.errorName = false;

      if (this.market.name === null || this.market.name === "") {
        this.errorName = true;
      }

      this.errorBtnSave = this.errorName;
    },
    checkName() {
      this.checkErrors();
    },
    async getMarket() {
      const market_id = this.$route.params.id;
      const response = await market.show(market_id);
      this.market = response.data.data;
    },
    async updateMarket() {
      try {
        const toaster = createToaster();
        const market_id = this.$route.params.id;
        if (this.market.name === null || this.market.name === "") {
          this.checkErrors();
        } else {
          const response = await market.update(this.market, market_id);
          if (response.status === 202) {
            toaster.success(response.data.message, { position: "top" });
            // setTimeout(() => {
            this.$router.push({ name: "market-index" });
            // }, 1000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>