import store from '../../store/store';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

export default (to , from , next) => {
    if(store.getters['authenticated'] && store.getters['roles'] === 'sales_manager_account'){
        if (from.path !="/commercial-screen/") {
            next();
        }
        console.log("commercial-screen")
    }else{
        toaster.error(`You don't have permission to view sales manager account data`, {position: 'top'});
        next({name: from.name});
    }
}