import store from '../../store/store';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

export default (to , from , next) => {
    if(store.getters['authenticated'] && (store.getters['roles'] === 'manager' || store.getters['roles'] === 'finance')){
        next();
    }else{
        toaster.error(`You don't have permission to view managers data` , {position: 'top'});
        next({name: from.name});
    }
}