<template>
  <div
    class="navbar overflow-hidden row justify-content-between align-items-center"
  >
    <div class="logo ps-4">
      <img src="../assets/images/new-logo.svg" alt="" />
    </div>
    <div
      class="data col-3 h-100 pe-4"
      v-if="isAccount === true || isAccount === undefined"
      @click="logout"
    >
      <!-- <h4 class="text-end mb-0" style="text-transform: capitalize">
        {{ role }}
      </h4> -->

      <!-- <font-awesome-icon icon="fa-solid fa-right-from-bracket" class="logout" /> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g
          id="vuesax_linear_logout"
          data-name="vuesax/linear/logout"
          transform="translate(-748 -444)"
        >
          <g id="logout">
            <path
              id="Vector"
              d="M0,5.07C.31,1.47,2.16,0,6.21,0h.13c4.47,0,6.26,1.79,6.26,6.26v6.52c0,4.47-1.79,6.26-6.26,6.26H6.21c-4.02,0-5.87-1.45-6.2-4.99"
              transform="translate(756.9 446.49)"
              fill="none"
              stroke="#3c3c3b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M11.38,0H0"
              transform="translate(751.62 456)"
              fill="none"
              stroke="#3c3c3b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M3.35,0,0,3.35,3.35,6.7"
              transform="translate(750.5 452.65)"
              fill="none"
              stroke="#3c3c3b"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
            <path
              id="Vector-4"
              data-name="Vector"
              d="M0,0H24V24H0Z"
              transform="translate(772 468) rotate(180)"
              fill="none"
              opacity="0"
            />
          </g>
        </g>
      </svg>
      <div class="logout_txt">Logout</div>
    </div>
  </div>
  <ConfirmationModal
    v-if="confirmModal"
    :message="confirmMessage"
    confirmBtn="Logout"
    cancleBtn="Cancel"
    @close-modal="confirmModalHandler($event)"
  />
</template>

<script>
import ConfirmationModal from "./ConfirmationModal";
export default {
  name: "navbar-component",
  props: ["isAccount"],
  data() {
    return {
      role: null,
      confirmModal: false,
      confirmMessage: null,
    };
  },
  mounted() {
    this.role = this.$store.state.auth.roles;
  },
  methods: {
    async logout() {
      this.confirmModal = true;
      this.confirmMessage = "Are you sure you want to logout?";
    },
    async confirmModalHandler(event) {
      if (event) {
        try {
          await this.$store.dispatch("logout");
          this.$router.push({ name: "login-page" });
        } catch (e) {
          console.log(e);
        }
      } else {
        this.confirmModal = false;
      }
    },
  },
  components: {
    ConfirmationModal,
  },
};
</script>

<style>
.navbar {
  max-width: 100vw;
  background-color: #fff;
  margin: 0 !important;
  color: #fff;
  border-bottom: 1px solid #a09f9f70;
  padding: 1rem 0 !important;
}
.logo {
  width: 20% !important;
}
.logo img {
  /* width: 100%; */
}
.logout_txt {
  color: #3c3c3b;
}
.data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap:0.5em;
}
.logout {
  margin-left: 2rem;
  color: #f47d1f;
  cursor: pointer;
  font-size: 2rem;
}
.drop-down-icon {
  margin-left: 0.5rem;
  color: #f47d1f;
  cursor: pointer;
}
</style>
