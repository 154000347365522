<template>
    <div class="container-fluid w-100 backgroundPage">
        <div class="row h-100 d-flex justify-content-center align-items-center">
            <div class="col-md-4 d-flex justify-content-center align-items-center flex-column">
                <h1 class="text-danger">ERROR <span class="statusCodde mb-1">404</span></h1>
                <p class="text-uppercase text-danger">Not Found</p>
                <button @click.prevent="goBackFunction" class="btnCss">
                    Go Back
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name:'error-component',
        methods: {
            goBackFunction(){
                this.$router.push('/login');
            }
        }
    }
</script>

<style scoped>
    .btnCss{
        background-color: #e65c00;
        color: #fff;
        text-decoration: none;
        width:50%;
        text-align: center;
        border-radius: 5px;
        padding: 10px;
        border: none;
    }
    .backgroundPage{
        /*background-color: transparent;*/
        background-image: url('../assets/images/home-bg.png');
        height: 100vh;
    }
    .imageContent{
        /*background-image: url("");*/
    }
    .statusCodde{
        color: #e65c00;
    }
</style>