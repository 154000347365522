import api from './api';

export default {
    index(page=1){
        return api.get(`${process.env.VUE_APP_URL}/business-units` , {params: {page:page}});
    },
    all(){
        return api.get(`${process.env.VUE_APP_URL}/business-units/get/all`);
    },
    allWithoutLoader(){
        return api.get(`${process.env.VUE_APP_URL}/business-units/get/all` , { 'headers': { 'loader': true } });
    },
    create(params){
        return api.post(`${process.env.VUE_APP_URL}/business-units` , params);
    },
    show(id){
        return api.get(`${process.env.VUE_APP_URL}/business-units/${id}`);
    },
    update(id , params){
        return api.put(`${process.env.VUE_APP_URL}/business-units/${id}` , params);
    },
    delete(id){
        return api.delete(`${process.env.VUE_APP_URL}/business-units/${id}`);
    },
}