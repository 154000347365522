<template>
  <Navbar />

  <div class="container-fluid m-0 p-0 home-page">
    <div class="d-flex h-100">
      <Sidebar class="sidebar" />
      <main
        class="w-100 cssDesignSidebar p-4 pt-0 overflow-auto pb-0 d-flex flex-column justify-content-sm-between"
      >
        <router-view />
        <footer class="text-center mt-auto text-white footer">
          <p>
            Copyright © {{ yearBefore }}-{{ yearNow }} Caduceuslane.com. All
            rights reserved.
          </p>
        </footer>
      </main>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
import store from "@/store/store";
export default {
  name: "wrapper-page",
  mounted() {
    const d = new Date();
    this.yearNow = d.getFullYear();
    this.yearBefore = d.getFullYear() - 1;
    this.checkRoutes();
  },
  data() {
    return {
      yearNow: null,
      yearBefore: null,
    };
  },
  methods: {
    checkRoutes() {
      if (
        store.getters["authenticated"] &&
        store.getters["roles"] === "manager"
      ) {
        this.$router.push({ path: "/home" });
      } else if (
        store.getters["authenticated"] &&
        store.getters["roles"] === "finance"
      ) {
        this.$router.push({ path: "/admin" });
      } else if (
        store.getters["authenticated"] &&
        store.getters["roles"] === "sales_account"
      ) {
        this.$router.push({ path: "/sales-account" });
      } else if (
        store.getters["authenticated"] &&
        store.getters["roles"] === "sales_manager_account"
      ) {
        this.$router.push({ path: "/sales-manager-account" });
      } else {
        this.$router.push({ path: "/market-impact-account" });
      }
    },
  },
  components: {
    Navbar,
    Sidebar,
  },
};
</script>

<style>
.home-page {
  /* background-image: url('../assets/images/home-bg.png'); */
  height: calc(100vh - 90px);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.cssDesignSidebar{
  margin-top: 1.5rem;
}
.sidebar {
  width: 15vw;
  /* background-color: #0f0f0eab;; */
  height: 100%;
  box-shadow: 1px 5px 8px 2px #707070;
}
main {
  position: relative;
  min-height: 80vh;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f47d1f;
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f1a500;
}

/* .footer{
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%)

    } */
</style>
