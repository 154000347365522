<template>
  <div class="cluster">
    <div class="header d-flex justify-content-between align-items-center">
      <div class="title">
        <h3>Clusters</h3>
      </div>
      <div class="">
        <router-link to="/create-cluster">
          <span
            class="create-button d-flex align-items-center justify-content-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="me-1"
              height="16"
              width="14"
              viewBox="0 0 448 512"
              fill="white"
            >
              <path
                d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
              />
            </svg>
            <div class="inline">Create Cluster</div>
          </span>
        </router-link>
      </div>
    </div>
  </div>
  <div class="cluster-table" v-if="clusters.length > 0">
    <TableComponent
      :thead="thead"
      :tbody="clusters"
      :keys="keys"
      model="clusters"
      name="cluster"
      @update="updateOrganization"
      updateUrl="/edit-cluster"
    />

    <div class="paginationLink">
      <pagination
        :data="laravelData"
        @pagination-change-page="getClusters"
        :limit="3"
      >
        <template #prev-nav>
          <span>Previous</span>
        </template>
        <template #next-nav>
          <span>Next</span>
        </template>
      </pagination>
    </div>
  </div>
  <div class="mb-5 no_data_container" v-else>
    <img src="./../../assets/images/no-data.png" alt="no data available">
    <h3>No Data Available Yet!</h3>
  </div>
</template>

<style scoped>
.title {
  color: #f47d1f;
  position: relative;
}
.title:after {
  position: absolute;
  content: "";
  width: 150%;
  height: 2px;
  background: #f47d1f50;
  bottom: 0;
}
.no_data_container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.no_data_container h3 {
  font-size: 16px !important;
  font-weight: 500;
  color: #a8a6a6;
}
div h3 {
  font-weight: 700;
  font-size: 22px;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.cell-one {
  padding-left: 1rem;
}
thead {
  background-color: #f47d1f !important;
}
tbody {
  background-color: #13161c !important;
  color: #fff;
}
thead {
  border-bottom: none;
}
tr {
  border-bottom: #1c1f24 solid 1px;
}
</style>

<script>
import TableComponent from "../../components/TableComponent";
import cluster from "../../api-services/cluster";
import LaravelVuePagination from "laravel-vue-pagination";

export default {
  name: "cluster-index",
  mounted() {
    this.getClusters();
    this.$store.dispatch("activeTap", "clusters");
  },
  data() {
    return {
      thead: ["Name", "Market", "Business Name",],
      keys: ["name", "market", "business_unit"],
      clusters: [],
      filteredArray: [],
      originalClusters: [],
      laravelData: {},
    };
  },
  methods: {
    async getClusters(page = 1) {
      try {
        const response = await cluster.index(page);
        this.clusters = response.data.data;
        this.laravelData = response.data;
        this.originalClusters = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    updateOrganization(e) {
      let filteredData = this.clusters.filter(function (data) {
        return data.id != e;
      });
      this.clusters = filteredData;
      this.getClusters();
    },
  },
  components: {
    TableComponent,
    Pagination: LaravelVuePagination,
  },
};
</script>