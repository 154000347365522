<template>
  <div class="target">
    <form class="form">
      <h3>Target</h3>
      <div class="filters">
        <div class="controls-container d-flex gap-2">
          <div class="form-group">
            <label for="" class="mb-2"> Market Country </label>
            <select
              class="form-select"
              id="inlineFormSelectPref"
              v-model="market_id"
              @change="selectMarket"
            >
              <option selected disabled value="null">Market Country</option>
              <option
                v-for="market in markets"
                :key="market.id"
                :value="market.id"
              >
                {{ market.name }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="" class="mb-2"> Business Unit </label>
            <select
              class="form-select"
              :class="{ disabled: isDisabled }"
              id="inlineFormSelectPrefBusinessUnit"
              v-model="business_unit_id"
              @change="selectBusinessUnit"
            >
              <option selected disabled value="null">Business Unit</option>
              <option
                v-for="business_unit in business_units"
                :key="business_unit.id"
                :value="business_unit.id"
              >
                {{ business_unit.name }}
              </option>
            </select>
          </div>
          <div class="d-flex align-items-end">
            <button
              @click.prevent="saveTarget"
              :class="{ disabled: isDisabledTwo }"
              class="btn filter-btn"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="target-body-container">
        <div v-if="clusters.length > 0">
          <div class="target-body">
            <div
              class="cluster-container"
              v-for="cluster in clusters"
              :key="cluster.id"
            >
              <div class="cluster-header">
                <h5
                  class="text-center m-0 text-capitalize"
                  v-b-tooltip.hover
                  :title="cluster.name"
                >
                  {{
                    cluster.name.slice(0, 30) +
                    (cluster.name.length > 30 ? "..." : "")
                  }}
                </h5>
              </div>
              <div class="clusters">
                <InputTargetComponent
                  :cluster="cluster.target"
                  :role="role"
                  :monthNow="monthNow"
                  :cluster_id="cluster.id"
                  @changed="checkTargetInput"
                  @errorInput="errorInput"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mb-5 no_data_container" v-else>
          <img
            src="./../../assets/images/no-data.png"
            alt="no data available"
          />
          <h3>Apply Filters and Filtered Data Will be Shown Here.</h3>
        </div>
      </div>
    </form>
  </div>
  <Model v-if="successModel" :message="successMessage" />
</template>

<style scoped>
.no_data_container {
  min-height: 70vh;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
}
.no_data_container h3 {
  font-size: 16px !important;
  font-weight: 500;
  color: #a8a6a6;
}
.target form h3 {
  font-weight: 800;
  font-size: 22px;
}
.controls-container {
  background-color: #f47d1f;
  padding: 1em;
  border-radius: 8px;
}
.form-group label {
  font-size: 18px;
  font-weight: 700;
  padding-left: 16px;
}
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f47d1f;
}
.disabled {
  pointer-events: none;
}
.filter-btn {
  font-weight: 600;
  padding: 8px 3rem;
  color: white !important;
  background-color: #3c3c3b !important;
  cursor: pointer;
  border-radius: 25px;
}
.filter-btn:hover {
  width: 100%;
  background-color: #e29119;
  color: #fff;
}
.filter-btn:focus {
  width: 100%;
  background-color: #e29119;
  color: #fff;
}
.filter-btn:active {
  width: 100%;
  background-color: #e29119;
  color: #fff;
}
.form-group {
  width: 32%;
}
.home {
  overflow-y: auto;
}
.target-body-container {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
}
.target-body {
  /* margin-top: 2rem; */
  width: 100%;
  /* border: solid 1px #707070;; */
  /* min-height: 70vh; */
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* padding: 1rem; */
  overflow: auto;
}
.cluster-container {
  width: 32%;
  margin-right: 0.5rem;
}
.clusters {
  height: 25vh;
  overflow-y: auto;
  margin-bottom: 0.5rem;
}
.cluster-header {
  background-color: #3c3c3b;
  color: #fff;
  padding: 0.8rem;
  margin-bottom: 0.3rem;
  border-radius: 10px;
}
.cluster-item {
  color: #707070;
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff;
  margin-bottom: 2px;
}
.cluster-item p {
  margin: 0;
}
.code {
  width: 35%;
  margin-right: 2px !important;
  padding: 0.5rem 1rem;
}
.details {
  width: 70%;
  padding: 0.5rem 0.25rem;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3C3C3B;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 16px;
  padding: 0.5em 1em;
}
/* label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
} */

/* ::placeholder {
  color: #fff;
  text-align: center;
}

:-ms-input-placeholder {
  color: #fff;
  text-align: center;
}

::-ms-input-placeholder {
  color: #fff;
  text-align: center;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button:hover,
input::-webkit-inner-spin-button:hover {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"]:hover {
  -moz-appearance: textfield;
}
</style>

<script>
import InputTargetComponent from "@/components/InputTargetComponent";
import business_unit from "../../api-services/business_unit";
import market from "../../api-services/market";
import target from "../../api-services/target";
import { createToaster } from "@meforma/vue-toaster";
import Model from "../../components/Model.vue";

export default {
  name: "target-index",
  async mounted() {
    this.$store.dispatch("activeTap", "targets");
    this.getMarkets();
    this.getBusinessUnits();

    const c = new Date();
    this.monthNow = c.getMonth() + 1;
    this.role = this.$store.state.auth.roles;
    await this.$store.dispatch("currentRoute", "target");
  },
  data() {
    return {
      successModel: false,
      successMessage: null,
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      markets: [],
      business_units: [],
      isDisabled: true,
      isDisabledTwo: true,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      targetMonths: [],
      clusterData: [],
      role: null,
      monthNow: null,
    };
  },
  methods: {
    errorInput(e) {
      if (e === true) {
        this.clusterData = [];
      }
      this.isDisabledTwo = e;
    },
    async getMarkets() {
      const responseMarkets = await market.index();
      this.markets = responseMarkets.data.data;
    },

    async getBusinessUnits() {
      const responseBusinessUnits = await business_unit.all();
      this.business_units = responseBusinessUnits.data.data;
    },

    async selectMarket(e) {
      this.market_id = e.target.value;
      this.isDisabled = false;

      const response = await target.getClustersLoader(
        this.market_id,
        this.business_unit_id
      );
      this.clusters = response.data.data;

      if (response.data.data.length > 0) {
        this.clusters.forEach((item) => {
          item.target.forEach((item2) => {
            this.targetMonths.push(item2.month);
          });

          let result = this.months.filter(
            (e) => !this.targetMonths.find((a) => e == a)
          );

          result.forEach((res) => {
            item.target.push({
              target: "",
              month: res,
              cluster_id: item.id,
              year: "",
            });
          });

          item.target.sort(function (a, b) {
            return a.month - b.month;
          });

          this.targetMonths = [];
        });
        this.isDisabledTwo = false;
      } else {
        this.isDisabledTwo = true;
      }
    },

    async selectBusinessUnit(e) {
      this.business_unit_id = e.target.value;
      const response = await target.getClustersLoader(
        this.market_id,
        this.business_unit_id
      );
      this.clusters = response.data.data;

      if (response.data.data.length > 0) {
        this.clusters.forEach((item) => {
          item.target.forEach((item2) => {
            this.targetMonths.push(item2.month);
          });

          let result = this.months.filter(
            (e) => !this.targetMonths.find((a) => e == a)
          );

          result.forEach((res) => {
            item.target.push({
              target: "",
              month: res,
              cluster_id: item.id,
              year: "",
            });
          });

          item.target.sort(function (a, b) {
            return a.month - b.month;
          });

          this.targetMonths = [];
        });
        this.isDisabledTwo = true;
      } else {
        this.isDisabledTwo = true;
      }
    },

    checkTargetInput(e) {
      this.clusterData.push(e);
    },

    async saveTarget() {
      try {
        const toaster = createToaster();
        let newArray = this.clusterData.filter((o) => o.target !== "");

        if (newArray.length > 0) {
          const response = await target.storeTargets({
            clusterTarget: newArray,
          });

          if (response.status === 200) {
            this.successModel = true;
            this.successMessage = response.data.message;
            setTimeout(() => {
              this.successModel = false;
              this.clusterData = [];
            }, 1000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        } else {
          toaster.warning(`Please enter valid target in any month`, {
            position: "top",
          });
        }
      } catch (e) {
        console.log(e.response.status);
        if (e.response.status === 422) {
          this.clusterData = [];
        }
      }
    },
  },
  components: {
    InputTargetComponent,
    Model,
  },
};
</script>