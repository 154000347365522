import api from './api';

export default {
    index(page=1){
        return api.get(`${process.env.VUE_APP_URL}/clusters` , {params: {page:page}});
    },
    allClusters(){
        return api.get(`${process.env.VUE_APP_URL}/clusters/all`);
    },
    getClusterByMarket(id){
        return api.get(`${process.env.VUE_APP_URL}/get/cluster/data/${id}` , { 'headers': { 'loader': true } });
    },
    create(params){
        return api.post(`${process.env.VUE_APP_URL}/clusters` , params);
    },
    show(id){
        return api.get(`${process.env.VUE_APP_URL}/clusters/${id}`);
    },
    update(id , params){
        return api.put(`${process.env.VUE_APP_URL}/clusters/${id}` , params);
    },
    delete(id){
        return api.delete(`${process.env.VUE_APP_URL}/clusters/${id}`);
    },
}