<template>
  <!-- <Navbar :isAccount="isAccount" /> -->
  <div class="market-impact-screen" id="marketImpactScreen">
    <div class="page-header ">
      <div class="d-flex align-items-end flex-grow-1 justify-content-end">
        <button class="btn screen-btn" @click.prevent="moveToHomeScreen">
          Insights
        </button>
      </div>
      <!-- <h2>Hello Will,</h2> -->
    </div>
    <div class="market-details">
      <div class="tab-header">
        <div class="row flex-nowrap market__row">
          <div class="col month headers">
            <h5 class="header__titles">Business Unit:</h5>
            <h6 class="details">
              {{ businessUnitShow?.name }}
            </h6>
          </div>
          <div class="col market headers">
            <h5 class="header__titles">Market:</h5>
            <h6 class="details">{{ marketShow.name }}</h6>
          </div>

          <div class="col day-to-goal headers">
            <h5 class="header__titles">Days to goal:</h5>
            <h6 class="details">{{ dateGoal }} day/s</h6>
          </div>
          <div class="col month headers">
            <h5 class="header__titles">Month:</h5>
            <h6 class="details">{{ monthName }}</h6>
          </div>
          <div class="col day-to-goal headers">
            <h5 class="header__titles">Date:</h5>
            <h6 class="details">{{ fullYear }}</h6>
          </div>
        </div>
      </div>

      <table class="table-container" v-if="Object.keys(clusterShow).length !== 0">
        <tr>
          <th class="table-header">Clusters</th>
          <th class="table-header">Cluster Quantum</th>
          <th class="table-header">Potentiality</th>
          <th class="table-header">Awarded</th>
          <th class="table-header">Conversion</th>
          <th class="table-header">Achievement</th>
          <th class="table-header">Cluster Quantum Index</th>
        </tr>

        <tr v-for="(cluster, idx) in sortedClusters" :key="idx">
          <td class="table-details">
            <span>{{ cluster.name }}</span>
          </td>
          <td class="table-details">
            <span>{{
              calculateQuantumPoints(cluster.goal).toLocaleString("en-US")
              }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.potentinality === null">0</span>
            <span v-else>{{
              calculatePoints(
              decimalNumber(cluster.potentinality),
              cluster.goal
              ).toLocaleString("en-US")
              }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.awarded === null">0</span>
            <span v-else>{{
              calculatePoints(decimalNumber(cluster.awarded), cluster.goal)
              }}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.awarded_count === null || cluster.awarded_count === 0
            ">0 %</span>
            <span v-else-if="cluster.potentinality_count === null ||cluster.potentinality_count === 0">0 %</span>
            <span v-else>{{ Math.round((cluster.awarded/ cluster.potentinality) * 100) + " %"}}</span>
          </td>
          <td class="table-details">
            <span v-if="cluster.po === null">0%</span>
            <span v-else-if="cluster.goal === 0">0%</span>
            <span v-else>{{
              Math.round((cluster.po / cluster.goal) * 100) + " %" ?? 0
              }}</span>
          </td>
          <td class="table-details">
            <span>{{
              calculatePoints(cluster.quantum_index, cluster.goal)
              }}</span>
          </td>
        </tr>
        <tr>
          <td class="table-details total__td">
            <span>Total Market Performance</span>
          </td>
          <td class="table-details total__td">
            <span>{{ totalGoalsInPoints.toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ totalPotentialityInPoints.toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ totalAwarded.toLocaleString("en-US") }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              this.totalConversion !== "NaN" ||
              this.totalConversion !== "Infinity"
              ? decimalNumber(this.totalConversion) + " %"
              : 0 + "%"
              }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{
              totalAchievements !== "NaN"
              ? decimalNumber(totalAchievements) + " %"
              : 0 + " %"
              }}</span>
          </td>
          <td class="table-details total__td">
            <span>{{ Math.round(totalQuantumIndex) }}</span>
          </td>
        </tr>
      </table>
      <div class="no-data" v-else>
        <h3 class="text-center">No Data</h3>
      </div>
    </div>
    <footer class="text-center footer">
      <p>
        Copyright © {{ yearBefore }}-{{ yearNow }} Caduceuslane.com. All rights
        reserved.
      </p>
    </footer>
  </div>
</template>

<style scoped>
.no-data {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #eca715;
  padding: 15%;
}

.market-impact-screen {
  background-color: #fff;

  height: calc(100vh - 100px);
  background-size: cover;
  background-repeat: no-repeat;
  overflow-x: hidden;
  padding: 0px 2rem;
  opacity: 1;
}

.market-impact-screen .market-details {
  min-height: 71vh;
  height: 81vh;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 0.5rem;
}

.market-impact-screen .tab-content {
  border: solid 1px #eca715;
  border-radius: 5px;
  min-height: 76vh;
  height: 76vh;
  padding: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.market-impact-screen .day-to-goal,
.market-impact-screen .month,
.market-impact-screen .market {
  width: 30% !important;
}

.market-impact-screen .headers {
  display: flex;
  flex-direction: column;
}

.market-impact-screen .headers h5 {
  color: #f47d1f;
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.market-impact-screen .details {
  color: #3c3c3b;
  font-size: 1.2rem;
  margin-bottom: 0;
  word-break: break-all;
  width: 60%;
}

.market-impact-screen .sec {
  width: 15%;
  /* background-color: #ECA715; */
  height: fit-content;
  margin-right: 5px;
}

.market-impact-screen .sec h5 {
  padding: 0.5rem;
  background-color: #eca715;
  border-radius: 5px;
}

.market-impact-screen .sec .cluster-details {
  padding: 0.5rem;
  background-color: #4c515c9a;
  color: #fff;
  border: solid 1px #907142;
  border-radius: 5px;
  margin-bottom: 5px;
}

.market-impact-screen .clusters {
  width: 20%;
}

.market-impact-screen .cluster-quantum-index {
  width: 20%;
}

.market-impact-screen .page-header {
  color: #fff;
  padding: 0;
}

.footer {
  color: #747474;
}

.market__row {
  border: 1px solid #dadada;
  border-radius: 17px;
  padding: 15px;
  margin-bottom: 1rem;
}

@media (width: 1535px) {
  .market-impact-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .market-impact-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1440px) {
  .market-impact-screen .sec .cluster-details {
    font-size: 0.75rem;
  }

  .market-impact-screen .sec h5 {
    font-size: 1rem;
  }
}

@media (width: 1050px) {
  .market-impact-screen .sec .cluster-details {
    font-size: 0.5rem;
  }

  .market-impact-screen .sec h5 {
    font-size: 0.8rem;
  }
}
</style>

<script>
import Navbar from "@/components/Navbar.vue";
import market from "../../api-services/market";
import business_unit from "../../api-services/business_unit";
import target from "../../api-services/target";
import axios from "axios";
import moment from "moment";
import store from "../../store/store";

export default {
  name: "market-impact-screen",
  data() {
    return {
      isAccount: false,
      markets: [],
      businessUnits: [],
      userBu: null,
      businessUnitParam: null,
      marketShow: {},
      curMarketIndex: -1,
      curMarketIndexNow: 1,
      businessUnitShow: {},
      curBusinessUnitIndex: -1,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      startNow: null,
      month: null,
      year: null,
      monthName: null,
      fullYear: null,
      emails: [],
      res: [],
      clusterShow: {},
      totalGoals: 0,
      totalGoalsInPoints: 0,
      totalPotentialityInPoints: 0,
      totalPotentiality: 0,
      totalPos: 0,
      totalAwarded: 0,
      currentGoal: 0,
      totalQuantumIndex: 0,
      totalConversion: 0,
      totalAchievements: 0,
      countClusters: 0,
      budgetWeightBusinessUnit: null,
      budgetLineWeightBusinessUnit: null,
      dateGoal: null,
      yearNow: null,
      yearBefore: null,
    };
  },
  mounted() {
    let userDataLocalStorage = JSON.parse(localStorage.getItem("user"));
    this.userBu = userDataLocalStorage.user.bu
    const d = new Date();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    this.quarter = Math.floor((this.month - 1) / 3) + 1; // Calculate the quarter of the year
    let lastDayOfQuarter = new Date(d.getFullYear(), this.quarter * 3, 0); // Last day of the quarter
    let today = new Date(d.getFullYear(), d.getMonth(), d.getDate()); // Today's date
    this.dateGoal = Math.ceil(
      (lastDayOfQuarter - today) / (1000 * 60 * 60 * 24)
    ); // Days remaining in the quarter
    this.monthName = d.toLocaleString("default", { month: "long" });
    this.fullYear = moment(d).format("DD MMM YYYY");
    this.yearNow = d.getFullYear();
    this.yearBefore = d.getFullYear() - 1;
    this.getMarkets();
  },

  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getMarkets() {
      try {
        const response = await market.indexWithoutLoading();
        this.markets = response.data.data;
        console.log("this.getMarkets")

        this.getBusinessUnits();
        store.dispatch("setIsLoading", true);
        localStorage.setItem("MarketsNumbers", response.data.data.length);
      } catch (e) {
        console.log(e);
      }
    },
    async getBusinessUnits() {
      try {
        const response = await business_unit.allWithoutLoader();
        let unitsToSet;
        let check = response?.data?.data.some(el => el.name === this.userBu)
        if (this.userBu && check) {
          unitsToSet = response?.data?.data.filter(unit => {
            return unit?.name === this.userBu
          });
          localStorage.setItem("businessUnitsNumbers", unitsToSet.length);
          this.businessUnits = unitsToSet;

        } else {
          this.businessUnits = response?.data?.data;

        }
        this.showPreview();
      } catch (e) {
        console.log(e);
      } 
    }

    ,

    moveToHomeScreen() {

      const url = `/market-impact-account`
      window.open(url);
    },

    async showPreview() {
      store.dispatch("setIsLoading", false);
      if (!this.businessUnits.length) { return; }
      else {
        for (let bu of this.businessUnits) {
          await this.showBusinessUnits(bu);
        }
        this.showPreview();
      }

    },
    async showBusinessUnits(businessUnit) {
      this.businessUnitShow = businessUnit;
      for (let market of this.markets) {
        this.marketShow = market;
        this.budgetWeightBusinessUnit = businessUnit.weight;
        this.budgetLineWeightBusinessUnit = businessUnit.line_weight;
        this.res = await target.getClustersReports(market.id, businessUnit.id);
        if (this.res.data.data.length > 0) {
          for (let i = 0; i < this.res.data.data.length; i++) {
            this.emails.push(this.res.data.data[i].email);
          }
        } else {
          this.clusterShow = {};
        }
        let emails = Object.values(this.emails);
        if (this.emails.length > 0) {
          store.dispatch("setIsLoading", true);
          await axios
            .post(process.env.VUE_APP_COUES_URL + "/marketImpactScreen", {
              email: emails,
              month: this.month,
              year: this.year,
              market: market.id,
            })
            .then((response) => {
              if (response.data) {
                store.dispatch("setIsLoading", false);
                this.hanldeDataFormat(response.data);
              }
            });
          this.emails = [];
        }
        await this.sleep(15000);
      }
    },

    formatNumber(num) {
      return parseFloat(+num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(+num));
    },
    hanldeDataFormat(data) {
      this.clusterShow = data;
      this.totalGoalsInPoints = 0;
      this.totalGoals = 0;
      this.totalPotentiality = 0;
      this.totalPotentialityInPoints = 0;
      this.totalAwarded = 0;
      this.totalPos = 0;
      this.totalConversion = 0;
      this.totalQuantumIndex = 0;
      this.awarded_count = 0;
      this.potentinality_count = 0;
      this.totalAchievements = 0;
      if (this.res.data.data.length > 0) {
        this.countClusters = this.res.data.data.length;
        for (let j = 0; j < this.res.data.data.length; j++) {
          this.clusterShow[this.res.data.data[j].email].name =
            this.res.data.data[j].name;
          if (this.res.data.data[j].current_target !== null) {
            this.clusterShow[this.res.data.data[j].email].goal =
              this.res.data.data[j].current_target.target;
            this.totalGoals +=
              +this.clusterShow[this.res.data.data[j].email].goal;

            this.totalGoalsInPoints += this.calculateQuantumPoints(
              parseFloat(this.clusterShow[this.res.data.data[j].email].goal)
            );
          } else {
            this.clusterShow[this.res.data.data[j].email].goal = 0;
          }
        }
      }
      for (let i = 0; i < Object.keys(data).length; i++) {
        this.clusterShow[Object.keys(data)[i]].email = Object.keys(data)[i];
        // this.currentGoal = this.clusterShow[Object.keys(data)[i]].goal;

        if (this.clusterShow[Object.keys(data)[i]].potentinality !== null) {
          // this.totalPotentiality += +this.clusterShow[Object.keys(data)[i]].potentinality;
          this.totalPotentiality +=
            +this.clusterShow[Object.keys(data)[i]].potentinality;
          this.totalPotentialityInPoints += this.calculatePoints(
            parseFloat(this.clusterShow[Object.keys(data)[i]].potentinality),
            parseFloat(this.clusterShow[Object.keys(data)[i]].goal)
          );
        } else {
          this.clusterShow[Object.keys(data)[i]].potentinality = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].po !== null) {
          this.totalPos += +this.clusterShow[Object.keys(data)[i]].po;
        } else {
          this.clusterShow[Object.keys(data)[i]].po = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].awarded !== null) {
          // this.totalAwarded += +this.clusterShow[Object.keys(data)[i]].awarded;
          this.totalAwarded += this.calculatePoints(
            +this.clusterShow[Object.keys(data)[i]].awarded,
            this.clusterShow[Object.keys(data)[i]].goal
          );
        } else {
          this.clusterShow[Object.keys(data)[i]].awarded = 0;
        }

        if (this.clusterShow[Object.keys(data)[i]].quantum_index !== null) {
          // this.totalQuantumIndex += +this.clusterShow[Object.keys(data)[i]].quantum_index;
          this.totalQuantumIndex += this.calculateTotalPoints(
            +this.clusterShow[Object.keys(data)[i]].quantum_index,
            this.clusterShow[Object.keys(data)[i]].goal
          );
        } else {
          this.clusterShow[Object.keys(data)[i]].quantum_index = 0;
        }

        this.clusterShow[Object.keys(data)[i]].conversion =
          (+this.clusterShow[Object.keys(data)[i]].potentinality /
            +this.clusterShow[Object.keys(data)[i]].po) *
          100;
      }

      let sum = 0;
      let result = 0;
      for (let key in data) {
        if (data[key].potentinality_count !== 0) {
          sum += data[key].awarded_count / data[key].potentinality_count;
        }
      }
      result = sum / Object.keys(data).length;
      if (Object.keys(data).length == 0) {
        result = 0;
      } else {
        result = sum / Object.keys(data).length;
      }
      this.totalConversion = Math.round(result * 100);

      if (this.totalGoals !== 0) {
        this.totalAchievements = (this.totalPos / this.totalGoals) * 100;
      } else {
        this.totalAchievements = 0;
      }
    },
    calculatePoints(amount, goal) {
      if (goal > 0) {
        return parseFloat(
          (
            (amount *
              (this.budgetWeightBusinessUnit /
                this.budgetLineWeightBusinessUnit)) /
            100
          ).toFixed(1)
        );
      }
      return 0;
    },

    calculateQuantumPoints(goal) {
      if (goal > 0) {
        return parseFloat(
          (
            (goal *
              (this.budgetWeightBusinessUnit /
                this.budgetLineWeightBusinessUnit)) /
            100
          ).toFixed(1)
        );
      }
      return 0;
    },
    calculateTotalPoints(amount, goal) {
      let total = 0;
      if (goal > 0) {
        total += Math.round(
          (amount / goal) *
          (this.budgetWeightBusinessUnit / this.budgetLineWeightBusinessUnit)
        );
      }
      return parseFloat(total);
    },
  },
  components: {
    Navbar,
  },
  computed: {

    sortedClusters() {
      return Object.values(this.clusterShow).sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    },
    queryParams() {
      const id = this.$route.query.token;
      console.log(`Query parameter id: ${id}`);
      return id;
    }
  },
};
</script>
