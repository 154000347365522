<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div class="modal" ref="myBtn" @click="$emit('close')">
      <div class="d-flex align-items-center gap-2 p-4 w-100 justify-content-start">
        <img src="../assets/images/success.png" alt="done" />
        <p class="cssMessage">{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "model-component",
  props: ["message"],
  data() {
    return {
      state: false,
    };
  },
};
</script>
<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #0e0e0e78;
  z-index: 100;
}

.modal {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: unset !important;
  background-color: #fff;
  height: 200px;
  border-radius: 20px;
  margin: auto;
  width: 40%;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 22px;
  margin: 30px 0;
  color: #f47d1f;
  font-weight: 700;
}
</style>