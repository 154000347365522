import api from './api';

export default {
    getClusters(market_id , business_unit_id){
        return api.get(`${process.env.VUE_APP_URL}/get-clusters/${market_id}/${business_unit_id}` , { 'headers': { 'loader': true } });
    },
    getClustersReports(market_id , business_unit_id){
        return api.get(`${process.env.VUE_APP_URL}/get-clusters/reports/${market_id}/${business_unit_id}`)
    },
    getClustersLoader(market_id , business_unit_id){
        return api.get(`${process.env.VUE_APP_URL}/get-clusters/${market_id}/${business_unit_id}`);
    },
    storeTargets(params){
        return api.post(`${process.env.VUE_APP_URL}/store-targets` , params);
    },
    getTargetByEmail(email){
        return api.get(`${process.env.VUE_APP_URL}/get/target/email/${email}`);
    }
}