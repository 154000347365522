<template>
  <div class="">
    <div class="header d-flex justify-content-between align-items-center">
      <div class="title">
        <h3>Markets</h3>
      </div>
      <div class="">
        <button
          @click.prevent="generateMarkets"
          class="create-button d-flex align-items-center justify-content-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="me-1"
            height="16"
            width="14"
            viewBox="0 0 448 512"
            fill="white"
          >
            <path
              d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
            />
          </svg>
          <div class="inline">Generate</div>
        </button>
      </div>
    </div>
  </div>
  <div class="market-table" v-if="markets.length > 0">
    <TableComponent
      :thead="thead"
      :tbody="markets"
      :keys="keys"
      model="markets"
      name="market"
      @update="updateOrganization"
      updateUrl="/edit-market"
    />

    <div class="paginationLink">
      <pagination
        :data="laravelData"
        @pagination-change-page="getMarkets"
        :limit="3"
      >
        <template #prev-nav>
          <span>Previous</span>
        </template>
        <template #next-nav>
          <span>Next</span>
        </template>
      </pagination>
    </div>
  </div>
  <div class="mb-5 no_data_container" v-else>
    <img src="./../../assets/images/no-data.png" alt="no data available">
    <h3>No Data Available Yet!</h3>
  </div>
  <Model v-if="successModel" :message="successMessage" />
</template>

<style scoped>
.title {
  color: #f47d1f;
  position: relative;
}
.title:after {
  position: absolute;
  content: "";
  width: 150%;
  height: 2px;
  background: #f47d1f50;
  bottom: 0;
}
.no_data_container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
  color: #707070;
}
.no_data_container h3 {
  font-size: 16px !important;
  font-weight: 500;
  color: #a8a6a6;
}
div h3 {
  font-weight: 700;
  font-size: 22px;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.cell-one {
  padding-left: 1rem;
}
thead {
  background-color: #eca715 !important;
}
tbody {
  background-color: #13161c !important;
  color: #fff;
}
thead {
  border-bottom: none;
}
tr {
  border-bottom: #1c1f24 solid 1px;
}
</style>


<script>
import TableComponent from "../../components/TableComponent";
import market from "../../api-services/market";
import LaravelVuePagination from "laravel-vue-pagination";
import Model from "../../components/Model.vue";

export default {
  name: "market-index",
  async mounted() {
    this.getMarkets();
    this.$store.dispatch("activeTap", "markets");
    await this.$store.dispatch("currentRoute", "market");
  },
  data() {
    return {
      successModel: false,
      successMessage: null,
      thead: ["Name"],
      keys: ["name"],
      markets: [],
      filteredArray: [],
      originalMarkets: [],
      laravelData: {},
    };
  },
  methods: {
    async getMarkets(page = 1) {
      try {
        const response = await market.index(page);
        this.markets = response.data.data;
        this.laravelData = response.data;
        this.originalMarkets = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    updateOrganization(e) {
      let filteredData = this.markets.filter(function (data) {
        return data.id != e;
      });
      this.markets = filteredData;
      this.getMarkets();
    },
    async generateMarkets() {
      try {
        // const toaster = createToaster();
        const response = await market.generate();
        // toaster.success(response.data.message , {position: 'top'});
        this.successModel = true;
        this.successMessage = response.data.message;
        setTimeout(() => {
          this.successModel = false;
          this.getMarkets();
        }, 1000);
        // window.location.reload();
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    TableComponent,
    Model,
    Pagination: LaravelVuePagination,
  },
};
</script>