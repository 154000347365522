<template>
  <div class="create-user">
    <form action="">
      <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
          
          <h3>Create User</h3>
        </div>
        <div class="">
          <div class="d-flex align-items-end">
            <button
              @click.prevent="saveUser"
              class="create-button"
              :class="[{ notAllowed: errorBtnSave }]"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="form-body">
        <div class="row mb-2">
          <div class="input-container">
            <label for="">Full Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Full Name"
              v-model="user.name"
              @input="checkName"
            />
            <span class="text-danger ms-2" v-if="errorFullName">*Required</span>
          </div>
          <div class="input-container">
            <label for="">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder="Email"
              v-model="user.email"
              @input="checkEmail"
            />
            <span class="text-danger ms-2" v-if="errorEmail">*Required</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="input-container">
            <label for="">Market</label>
            <select
              class="form-select"
              v-model="user.market_id"
              @change="checkMarket"
            >
              <option selected disabled value="null">Market</option>
              <option
                v-for="market in markets"
                :key="market.id"
                :value="market.id"
              >
                {{ market.name }}
              </option>
            </select>
            <span class="text-danger ms-2" v-if="errorMarket">*Required</span>
          </div>
          <div class="input-container">
            <label for="">Role</label>
            <select class="form-select" v-model="user.role" @change="checkRole">
              <option selected disabled value="null">Role</option>
              <option v-for="role in roles" :key="role.id" :value="role.name">
                {{ role.name }}
              </option>
            </select>
            <span class="text-danger ms-2" v-if="errorRole">*Required</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="input-container">
            <label for="">Password</label>
            <div class="position-relative">
              <input
                v-if="isActive1 === false"
                type="password"
                class="form-control"
                placeholder="Password"
                v-model="user.password"
                @input="checkPassword"
              />
              <input
                v-if="isActive1 === true"
                type="text"
                class="form-control"
                placeholder="Password"
                v-model="user.password"
                @input="checkPassword"
              />
              <!-- <img src="../../assets/images/show-password.png" class="show-password-img" alt="" /> -->
              <font-awesome-icon
                v-if="isActive1 === false"
                :icon="['fas', 'eye']"
                class="show-password-img"
                size="lg"
                @click="toggle1"
              />
              <font-awesome-icon
                v-if="isActive1 === true"
                :icon="['fas', 'eye-slash']"
                class="show-password-img"
                size="lg"
                @click="toggle1"
              />
            </div>
            <span class="text-danger ms-2" v-if="errorPassword">*Required</span>
          </div>
          <div class="input-container">
            <label for="">Confirm Password</label>
            <div class="position-relative">
              <input
                v-if="isActive2 === false"
                type="password"
                class="form-control"
                placeholder="Confirm Password"
                v-model="user.password_confirmation"
                @input="checkPasswordConfirmation"
              />
              <input
                v-if="isActive2 === true"
                type="text"
                class="form-control"
                placeholder="Confirm Password"
                v-model="user.password_confirmation"
                @input="checkPasswordConfirmation"
              />
              <!-- <img src="../../assets/images/show-password.png" class="show-password-img" alt="" /> -->
              <font-awesome-icon
                v-if="isActive2 === false"
                :icon="['fas', 'eye']"
                class="show-password-img"
                size="lg"
                @click="toggle2"
              />
              <font-awesome-icon
                v-if="isActive2 === true"
                :icon="['fas', 'eye-slash']"
                class="show-password-img"
                size="lg"
                @click="toggle2"
              />
            </div>
            <span class="text-danger ms-2" v-if="errorPasswordConfirmation"
              >*Required</span
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <Model v-if="successModel" :message="successMessage" />
</template>

<style scoped>
::placeholder {
  color: #fff !important;
}
.allowed {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.form-body {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  padding: 1rem;
  overflow: auto;
  color: #fff;
}
.form-body .input-container {
  width: 40%;
}
.form-body .input-container label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3C3C3B;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 14px;
  padding: 0.5em 1em;
}
.form-control {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 25px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";
import roleService from "../../api-services/role-service";
import manager from "../../api-services/manager";
import Model from "../../components/Model.vue";
export default {
  name: "create-user",
  async mounted() {
    this.getRoles();
    this.getMarkets();
    this.errorBtnSave = true;
    this.$store.dispatch("activeTap", "users");
    await this.$store.dispatch("currentRoute", "create-user");
  },
  data() {
    return {
      successModel: false,
      successMessage: null,
      roles: [],
      markets: [],
      user: {
        name: null,
        email: null,
        market_id: null,
        role: null,
        password: null,
        password_confirmation: null,
      },
      errorFullName: false,
      errorEmail: false,
      errorMarket: false,
      errorRole: false,
      errorPassword: false,
      errorPasswordConfirmation: false,
      errorBtnSave: false,
      isActive1: false,
      isActive2: false,
    };
  },
  methods: {
    toggle1() {
      if (!this.isActive1) {
        this.isActive1 = true;
      } else {
        this.isActive1 = false;
      }
    },
    toggle2() {
      if (!this.isActive2) {
        this.isActive2 = true;
      } else {
        this.isActive2 = false;
      }
    },
    async getRoles() {
      try {
        const response = await roleService.getRoles();
        this.roles = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getMarkets() {
      try {
        const response = await roleService.getMarkets();
        this.markets = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    checkErrors() {
      this.errorFullName = false;
      this.errorEmail = false;
      this.errorMarket = false;
      this.errorRole = false;
      this.errorPassword = false;
      this.errorPasswordConfirmation = false;

      if (this.user.name === null || this.user.name === "") {
        this.errorFullName = true;
      }

      if (this.user.email === null || this.user.email === "") {
        this.errorEmail = true;
      }

      if (this.user.market_id === null) {
        this.errorMarket = true;
      }

      if (this.user.role === null) {
        this.errorRole = true;
      }

      if (this.user.password === null || this.user.password === "") {
        this.errorPassword = true;
      }

      if (
        this.user.password_confirmation === null ||
        this.user.password_confirmation === ""
      ) {
        this.errorPasswordConfirmation = true;
      }

      this.errorBtnSave =
        this.errorFullName ||
        this.errorEmail ||
        this.errorMarket ||
        this.errorRole ||
        this.errorPassword ||
        this.errorPasswordConfirmation;
    },
    checkName() {
      this.checkErrors();
    },
    checkEmail() {
      this.checkErrors();
    },
    checkMarket() {
      this.checkErrors();
    },
    checkRole() {
      this.checkErrors();
    },
    checkPassword() {
      this.checkErrors();
    },
    checkPasswordConfirmation() {
      this.checkErrors();
    },
    async saveUser() {
      const regName = /^[a-zA-Z ]*$/;
      const regMail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      try {
        const toaster = createToaster();
        if (
          this.user.name === null ||
          this.user.name === "" ||
          this.user.email === null ||
          this.user.email === "" ||
          this.user.market_id === null ||
          this.user.role === null ||
          this.user.password === null ||
          this.user.password === "" ||
          this.user.password_confirmation === null ||
          this.user.password_confirmation === ""
        ) {
          this.checkErrors();
        } else if (!isNaN(this.user.name) || !regName.test(this.user.name)) {
          toaster.error(`Please enter the valid name`, { position: "top" });
        } else if (!regMail.test(this.user.email)) {
          toaster.error(`Please enter valid email`, { position: "top" });
        } else if (this.user.password !== this.user.password_confirmation) {
          toaster.error(`Password doesn't match`, { position: "top" });
        } else {
          const response = await manager.create(this.user);
          if (response.status === 201) {
            this.successModel = true;
            this.successMessage = response.data.message;
            setTimeout(() => {
              this.successModel = false;
              this.$router.push({ name: "users-index" });
            }, 1000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    Model,
  },
};
</script>
