import guest from './rules/guest';
import user from './rules/user';
import Manager from './rules/Manager';
import Finance from './rules/Finance';
import SalesAccount from './rules/SalesAccount';
import SalesManagerAccount from './rules/SalesManagerAccount';
import MarketImpactAccount from './rules/MarketImpactAccount';
import managerFinanceRule from './rules/managerFinanceRule';
import canViewCommericialScreen from './rules/canViewCommericialScreen';
import canViewMarketImpactScreen from './rules/canViewMarketImpactScreen';

export default {
    guest , user , Manager , Finance , SalesAccount , SalesManagerAccount , MarketImpactAccount , managerFinanceRule , canViewCommericialScreen , canViewMarketImpactScreen
};