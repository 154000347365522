<template>
  <router-view />

  <div class="loading-overlay" id="loading" v-if="isLoading">
    <div class="reverse-spinner"></div>
  </div>

  <!-- <div class="rotate-message">
     <div class="msg-container">
        <div class="icon-container">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" class="ex-icon" />
        </div>
        <h6>Mobile version doesn't support this application</h6>
     </div>
  </div> -->

  <div class="rotate">
    <div class="phone"></div>
    <div class="message">
      <font-awesome-icon
        icon="fa-solid fa-triangle-exclamation"
        class="ex-icon"
      />
      <h6>Mobile version doesn't support this application</h6>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  computed: {
    isLoading() {
      return this.$store.state.auth.isLoading;
    },
  },
};
</script>

<style>
.ex-icon {
  font-size: 4rem;
  color: #f7931e;
}
.icon-container {
  width: 100px;
  height: 100px;
  margin: auto;
  border: solid 1px #484646;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-bottom: 1rem;
  padding: 2rem;
}

.rotate {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100vh;
  z-index: 99999;
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-90deg);
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.pagination .page-item {
  width: 40px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #777777;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  overflow: hidden;
}
.pagination .page-link {
  font-size: 12px;
  padding: 0px 0.2rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}
.pagination-prev-nav {
  width: 70px !important ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination-next-nav {
  width: 70px !important ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-link:hover {
  border: none;
  background-color: #EBEBEB;
}
.pagination .page-link:focus {
  border: none;
  background-color: #777777;
}

.page-link.active,
.disabled > .page-link {
  /* z-index: 3; */
  /* color: var(--bs-pagination-active-color); */
  background-color: #777777 !important;
  border: none;
  outline: none !important;
}
.page-link.active,
.active > .page-link {
  border: none;
  outline: none !important;
  background-color: #f47d1f !important;
}
.sr-only {
  display: none;
}

option {
  background-color: #fff;
  color: #3C3C3B;
}
.form-select {
  position: relative;
  font-weight: 600;
  cursor: pointer;
  border-radius: 25px !important;
}
.show-password-img {
  position: absolute;
  right: 1%;
  bottom: 23%;
  cursor: pointer;
  width: 30px;
  color: #f47d1f;
}
/* start style of the spin  */
.overlay-hidden {
  display: none !important;
}

.loading-overlay {
  background-color: #0e0e0e78 !important;
  color: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.reverse-spinner {
  position: relative;
  height: 150px;
  width: 150px;
  border: 6px solid transparent;
  border-top-color: #f7931e;
  border-left-color: #f7931e;
  border-radius: 50%;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.reverse-spinner::before {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  content: "";
  border: 6px solid transparent;
  border-top-color: #9ba3a9;
  border-left-color: #a6b1b9;
  border-radius: 50%;
  -webkit-animation: spinBack 1s linear infinite;
  animation: spinBack 1s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinBack {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@keyframes spinBack {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(-720deg);
    transform: rotate(-720deg);
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .rotate {
    background: black;
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rotate .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    -webkit-animation: rotate 1.5s ease-in-out infinite alternate;
    animation: rotate 1.5s ease-in-out infinite alternate;
    display: block !important;
  }

  .rotate .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    display: block !important;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .rotate {
    background: black;
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rotate .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    -webkit-animation: rotate 1.5s ease-in-out infinite alternate;
    animation: rotate 1.5s ease-in-out infinite alternate;
    display: block !important;
  }

  .rotate .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    display: block !important;
    text-align: center;
  }
}

@media only screen and (orientation: portrait) {
  .rotate {
    background: black;
    height: 100%;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .rotate .phone {
    height: 50px;
    width: 100px;
    border: 3px solid white;
    border-radius: 10px;
    -webkit-animation: rotate 1.5s ease-in-out infinite alternate;
    animation: rotate 1.5s ease-in-out infinite alternate;
    display: block !important;
  }

  .rotate .message {
    color: white;
    font-size: 1em;
    margin-top: 40px;
    display: block !important;
    text-align: center;
  }
  .rotate-message .msg-container {
    width: 80%;
  }
}
</style>
