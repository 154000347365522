<template>
  <div class="sidebar" v-if="role === 'manager' || role === 'finance'">
    <ul>
      <li :class="{ active: activeTap === 'home' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager'"
          to="/home">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            width="16"
            height="16"
            viewBox="0 0 21.445 21.411">
            <defs>
              <clipPath id="clip-path">
                <rect
                  id="Rectangle_151758"
                  data-name="Rectangle 151758"
                  width="21.445"
                  height="21.411"
                  fill="none" />
              </clipPath>
            </defs>
            <g
              id="Group_173093"
              data-name="Group 173093"
              transform="translate(0 0)">
              <g
                id="Group_173092"
                data-name="Group 173092"
                transform="translate(0 0)"
                clip-path="url(#clip-path)">
                <path
                  id="Path_85584"
                  data-name="Path 85584"
                  d="M16.228,21.41c-.06,0-.121,0-.18-.005-.515-.013-1.061-.01-1.6-.006-.352,0-.707.006-1.058,0a1.567,1.567,0,0,1-1.716-1.72V15.516a1.387,1.387,0,0,0-.015-.223,1.372,1.372,0,0,0-.23-.017l-.521,0c-.342,0-.685,0-1.027,0l0,.049-.07.028q-.007,1.41,0,2.82l0,1.414A1.6,1.6,0,0,1,8.023,21.4h-.9c-.6,0-1.207,0-1.813,0A3.074,3.074,0,0,1,2.12,18.523L2.109,18.4a3.1,3.1,0,0,1-.026-.332l-.01-3.451c0-.438,0-.875,0-1.314A2.726,2.726,0,0,1,.391,9.248,3.845,3.845,0,0,1,1.013,8.5L3.071,6.446C4.888,4.639,6.767,2.771,8.582.9a3.01,3.01,0,0,1,2.14-.9,3.059,3.059,0,0,1,2.143.876c1.763,1.813,3.555,3.6,5.287,5.321q1.2,1.191,2.391,2.385a2.835,2.835,0,0,1,.423,3.651,2.506,2.506,0,0,1-1.612,1.1c0,.526,0,1.033-.005,1.542-.006,1.087-.013,2.174.021,3.26a3.147,3.147,0,0,1-3,3.273l-.14,0m-1.074-1.843c.324,0,.648,0,.972.012.072,0,.114,0,.156,0a1.32,1.32,0,0,0,1.26-1.376c-.036-1.127-.029-2.251-.023-3.339,0-.642.007-1.284,0-1.926a1.381,1.381,0,0,1,.36-1.019,1.315,1.315,0,0,1,.974-.365.7.7,0,0,0,.581-.325,1.016,1.016,0,0,0-.154-1.323c-.83-.829-1.626-1.622-2.422-2.414-1.737-1.73-3.534-3.519-5.274-5.31a1.2,1.2,0,0,0-.851-.355H10.73a1.187,1.187,0,0,0-.843.354c-1.819,1.874-3.7,3.747-5.527,5.56L2.284,9.812a2.083,2.083,0,0,0-.338.4.874.874,0,0,0-.114.372.9.9,0,0,0,.824.97A1.241,1.241,0,0,1,3.9,12.919c0,.467,0,.937,0,1.407l.011,0V18.06a.943.943,0,0,0,.013.115l.021.254a1.236,1.236,0,0,0,1.309,1.141c.661,0,1.262,0,1.861,0h.866l0-1.393q0-1.4,0-2.792a1.819,1.819,0,0,1,1.95-1.935c.273-.007.625-.005.979,0l.518,0A1.871,1.871,0,0,1,13.5,15.516v4.055c.313,0,.625,0,.934,0l.723,0"
                  transform="translate(0 0)" />
              </g>
            </g>
          </svg>
          <span>Home</span>
        </router-link>
        <router-link class="d-flex gap-1 align-items-center" v-else to="/admin">
          <span>Home</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'users' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager'"
          to="/users">
          <!-- <font-awesome-icon icon="fa-solid fa-user" class="me-2" /> -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24">
            <g
              id="vuesax_linear_profile-2user"
              data-name="vuesax/linear/profile-2user"
              transform="translate(-172 -252)">
              <g id="profile-2user">
                <path
                  id="Vector"
                  d="M4.6,8.87a1.818,1.818,0,0,0-.33,0,4.445,4.445,0,1,1,.33,0Z"
                  transform="translate(176.56 254)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M.13,0a3.5,3.5,0,0,1,3.5,3.5A3.5,3.5,0,0,1,.26,7,1.129,1.129,0,0,0,0,7"
                  transform="translate(188.28 256)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M1.815,1.373c-2.42,1.62-2.42,4.26,0,5.87a9.766,9.766,0,0,0,10.01,0c2.42-1.62,2.42-4.26,0-5.87A9.812,9.812,0,0,0,1.815,1.373Z"
                  transform="translate(174.345 265.188)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-4"
                  data-name="Vector"
                  d="M0,6a4.837,4.837,0,0,0,1.96-.87,2.533,2.533,0,0,0,0-4.27A4.973,4.973,0,0,0,.03,0"
                  transform="translate(190.34 266)"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-5"
                  data-name="Vector"
                  d="M0,0H24V24H0Z"
                  transform="translate(196 276) rotate(180)"
                  fill="none"
                  opacity="0" />
              </g>
            </g>
          </svg>
          <span>Users</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'business_units' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager' || role === 'finance'"
          to="/business-unit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24">
            <g
              id="vuesax_linear_bank"
              data-name="vuesax/linear/bank"
              transform="translate(-428 -188)">
              <g id="bank">
                <path
                  id="Vector"
                  d="M10.37.06l9,3.6a1.083,1.083,0,0,1,.63.93V7.91a1,1,0,0,1-1,1H1a1,1,0,0,1-1-1V4.59a1.083,1.083,0,0,1,.63-.93l9-3.6A1.171,1.171,0,0,1,10.37.06Z"
                  transform="translate(430 190.09)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M20,4H0V1A1,1,0,0,1,1,0H19a1,1,0,0,1,1,1Z"
                  transform="translate(430 206)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M0,7V0"
                  transform="translate(432 199)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-4"
                  data-name="Vector"
                  d="M0,7V0"
                  transform="translate(436 199)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-5"
                  data-name="Vector"
                  d="M0,7V0"
                  transform="translate(440 199)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-6"
                  data-name="Vector"
                  d="M0,7V0"
                  transform="translate(444 199)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-7"
                  data-name="Vector"
                  d="M0,7V0"
                  transform="translate(448 199)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-8"
                  data-name="Vector"
                  d="M0,0H22"
                  transform="translate(429 210)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-9"
                  data-name="Vector"
                  d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"
                  transform="translate(438.5 193.5)"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-10"
                  data-name="Vector"
                  d="M0,0H24V24H0Z"
                  transform="translate(428 188)"
                  fill="none"
                  opacity="0" />
              </g>
            </g>
          </svg>
          <span>Business units</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'markets' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager'"
          to="/market">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24">
            <g
              id="vuesax_linear_ranking"
              data-name="vuesax/linear/ranking"
              transform="translate(-492 -636)">
              <g id="ranking">
                <path
                  id="Vector"
                  d="M6.67,0H2A2.006,2.006,0,0,0,0,2V8H6.67Z"
                  transform="translate(494 650)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M4.67,0H2A2.006,2.006,0,0,0,0,2V12H6.67V2A2,2,0,0,0,4.67,0Z"
                  transform="translate(500.66 646)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M4.67,0H0V5H6.67V2A2.006,2.006,0,0,0,4.67,0Z"
                  transform="translate(507.33 653)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <g id="Group">
                  <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M3.526.426l.53,1.06a.6.6,0,0,0,.42.31l.96.16c.61.1.76.55.32.98l-.75.75a.639.639,0,0,0-.16.54l.21.92c.17.73-.22,1.01-.86.63l-.9-.53a.618.618,0,0,0-.59,0l-.9.53c-.64.38-1.03.1-.86-.63l.21-.92A.63.63,0,0,0,1,3.686l-.74-.74c-.44-.44-.3-.88.32-.98l.96-.16a.666.666,0,0,0,.42-.31l.53-1.06C2.776-.144,3.236-.144,3.526.426Z"
                    transform="translate(500.994 637.644)"
                    fill="none"
                    stroke="#3C3C3B"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5" />
                </g>
                <path
                  id="Vector-5"
                  data-name="Vector"
                  d="M0,0H24V24H0Z"
                  transform="translate(492 636)"
                  fill="none"
                  opacity="0" />
              </g>
            </g>
          </svg>
          <span>Markets</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'clusters' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager' || role === 'finance'"
          to="/cluster">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24">
            <g
              id="vuesax_linear_hierarchy"
              data-name="vuesax/linear/hierarchy"
              transform="translate(-236 -188)">
              <g id="hierarchy">
                <path
                  id="Vector"
                  d="M0,0V7"
                  transform="translate(241 197)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-2"
                  data-name="Vector"
                  d="M6.5,3.25A3.25,3.25,0,1,1,3.25,0,3.25,3.25,0,0,1,6.5,3.25Z"
                  transform="translate(238 190)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-3"
                  data-name="Vector"
                  d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z"
                  transform="translate(238 204)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-4"
                  data-name="Vector"
                  d="M6,3A3,3,0,1,1,3,0,3,3,0,0,1,6,3Z"
                  transform="translate(252 204)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-5"
                  data-name="Vector"
                  d="M0,0A4.058,4.058,0,0,0,3.94,3.04l3.43-.01a5.989,5.989,0,0,1,5.67,4.01"
                  transform="translate(241.13 197)"
                  fill="none"
                  stroke="#3C3C3B"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5" />
                <path
                  id="Vector-6"
                  data-name="Vector"
                  d="M0,0H24V24H0Z"
                  transform="translate(236 188)"
                  fill="none"
                  opacity="0" />
              </g>
            </g>
          </svg>
          <span>Clusters</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'targets' }">
        <router-link
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager' || role === 'finance'"
          to="/target">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24">
            <g id="trend-up" transform="translate(-620 -188)">
              <path
                id="Vector"
                d="M9,0,4.8,4.2,3.2,1.8,0,5"
                transform="translate(627.5 197.5)"
                fill="none"
                stroke="#3C3C3B"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5" />
              <path
                id="Vector-2"
                data-name="Vector"
                d="M0,0H2V2"
                transform="translate(634.5 197.5)"
                fill="none"
                stroke="#3C3C3B"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5" />
              <path
                id="Vector-3"
                data-name="Vector"
                d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z"
                transform="translate(622 190)"
                fill="none"
                stroke="#3C3C3B"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5" />
              <path
                id="Vector-4"
                data-name="Vector"
                d="M0,0H24V24H0Z"
                transform="translate(620 188)"
                fill="none"
                opacity="0" />
            </g>
          </svg>
          <span>Targets</span>
        </router-link>
      </li>
      <li :class="{ active: activeTap === 'export' }">
        <a
          class="d-flex gap-1 align-items-center"
          v-if="role === 'manager'"
          href="https://quantum-control.nexus-ecosystem.com/api/targets/export"
          target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 576 512">
            <path
              d="M88.7 223.8L0 375.8V96C0 60.7 28.7 32 64 32H181.5c17 0 33.3 6.7 45.3 18.7l26.5 26.5c12 12 28.3 18.7 45.3 18.7H416c35.3 0 64 28.7 64 64v32H144c-22.8 0-43.8 12.1-55.3 31.8zm27.6 16.1C122.1 230 132.6 224 144 224H544c11.5 0 22 6.1 27.7 16.1s5.7 22.2-.1 32.1l-112 192C453.9 474 443.4 480 432 480H32c-11.5 0-22-6.1-27.7-16.1s-5.7-22.2 .1-32.1l112-192z" />
          </svg>
          <span>Export</span>
        </a>
    
      </li>
    </ul>
  </div>
  <div style="display: none" v-else></div>
</template>

<script>
import { computed } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "sidebar-component",
  data() {
    return {
      role: null,
      active: computed(() => this.$store.state.settings.activeTap),
    };
  },
  mounted() {
    this.role = this.$store.state.auth.roles;
  },
  computed: {
    ...mapGetters(["activeTap"]),
  },
};
</script>

<style>
ul {
  list-style: none;
  width: 100%;
  padding-left: 0 !important;
  margin-top: 1rem !important;
}
li {
  text-align: left;
  width: 100%;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  position: relative;
}
li:hover a {
  background-color: #f47d1f50;
  color: #f47d1f !important;
}
.sidebar li.active a {
  color: #f47d1f !important;
  background-color: #f47d1f50;
}
.sidebar li.active:before {
  position: absolute;
  top: 0;
  content: "";
  width: 4px;
  height: 100%;
  background-color: #f47d1f;
}
.sidebar li a svg path {
  fill: #3c3c3b;
}
.sidebar li.active a svg path {
  fill: #f47d1f !important;
}
.sidebar li:hover a svg path {
  fill: #f47d1f !important;
}
.sidebar li.active a #vuesax_linear_profile-2user path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li:hover a #vuesax_linear_profile-2user path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li a #vuesax_linear_bank path {
  stroke: #3c3c3b !important;
}
.sidebar li.active a #vuesax_linear_bank path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li:hover a #vuesax_linear_bank path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li a #vuesax_linear_ranking path {
  stroke: #3c3c3b !important;
  fill: white;
}
.sidebar li.active a #vuesax_linear_ranking path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li:hover a #vuesax_linear_ranking path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li:hover a #vuesax_linear_hierarchy path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li.active a #vuesax_linear_hierarchy path {
  fill: #f47d1f50 !important;
  stroke: #f47d1f !important;
}
.sidebar li a #trend-up path {
  stroke: #3c3c3b !important;
  fill: none !important;
}
.sidebar li:hover a #trend-up path {
  stroke: #f47d1f !important;
}
.sidebar li.active a #trend-up path {
  stroke: #f47d1f !important;
}
li a {
  padding: 1rem 2rem;
  color: #3c3c3b !important;
  display: inline-block;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none !important;
}
@media only screen and (max-width: 1440px) and (orientation: landscape) {
  li a {
    padding: 1.5rem 1.5rem;
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 1050px) and (orientation: landscape) {
  li a {
    padding: 1.5rem 1rem;
    font-size: 0.8rem;
  }
}
</style>
