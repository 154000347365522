<template>
  <div class="edit-business-unit">
    <form action="">
      <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
          <h3>Edit Business Unit</h3>
        </div>
        <div class="">
          <div class="d-flex align-items-end">
            <button
              @click.prevent="updateBusinessUnit"
              class="create-button"
              :class="[{ notAllowed: errorBtnSave }]"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="form-body">
        <div class="input-container mb-2">
          <label class="mb-2">Business Unit Name</label>
          <input
            type="text"
            class="form-control"
            placeholder="Business Unit Name"
            v-model="business_unit.name"
            @input="checkName"
          />
          <span class="text-danger" v-if="errorName">*Required</span>
        </div>

        <div class="input-container mb-2">
          <label class="mb-2">Budget Weight </label>
          <input
            type="text"
            class="form-control"
            placeholder="Budget Weight"
            v-model="business_unit.weight"
            @input="checkBudgetWeight"
          />
          <span class="text-danger" v-if="errorBudgetWeight">*Required</span>
        </div>
        <div class="input-container mb-2">
          <label class="mb-2">Business Lines Weight </label>
          <input
            type="text"
            class="form-control"
            placeholder="Business Lines Weight"
            v-model="business_unit.line_weight"
            @input="checkLineWeight"
          />
          <span class="text-danger" v-if="errorLineWeight">*Required</span>
        </div>
      </div>
    </form>
  </div>
  <Model v-if="successModel" :message="successMessage" />
</template>

<style scoped>
::placeholder {
  color: #fff !important;
}
.allowed {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.form-body {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  /* display: flex; */
  /* flex-wrap: wrap;
        justify-content: space-around; */
  padding: 1rem;
  overflow: auto;
  color: #fff;
}
.form-body .input-container {
  width: 40%;
}
.form-body .input-container label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3C3C3B;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 14px;
  padding: 0.5em 1em;
}
.form-control {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 25px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";
import business_unit from "../../api-services/business_unit";
import Model from "../../components/Model.vue";

export default {
  name: "edit-business-unit",
  async mounted() {
    const businessUnitId = this.$route.params.id;
    this.getBusinessUnitData();
    this.$store.dispatch("activeTap", "business_units");
    await this.$store.dispatch(
      "currentRoute",
      "edit-business-unit/" + businessUnitId
    );
  },
  data() {
    return {
      successModel: false,
      successMessage: null,
      business_unit: {
        name: null,
        weight: null,
        line_weight: null,
      },

      errorName: false,
      errorBudgetWeight: false,
      errorLineWeight: false,
      errorBtnSave: false,
    };
  },
  methods: {
    async getBusinessUnitData() {
      const business_unit_id = this.$route.params.id;
      const response = await business_unit.show(business_unit_id);
      this.business_unit = response.data.data;
    },
    checkErrors() {
      this.errorName = false;
      this.errorBudgetWeight = false;
      this.errorLineWeight = false;

      if (this.business_unit.name === null || this.business_unit.name === "") {
        this.errorName = true;
      }

      if (
        this.business_unit.weight === null ||
        this.business_unit.weight === ""
      ) {
        this.errorBudgetWeight = true;
      }

      if (
        this.business_unit.line_weight === null ||
        this.business_unit.line_weight === ""
      ) {
        this.errorLineWeight = true;
      }

      this.errorBtnSave =
        this.errorName || this.errorBudgetWeight || this.errorLineWeight;
    },
    checkName() {
      this.checkErrors();
    },
    checkBudgetWeight() {
      this.checkErrors();
    },
    checkLineWeight() {
      this.checkErrors();
    },

    async updateBusinessUnit() {
      try {
        const toaster = createToaster();
        const business_unit_id = this.$route.params.id;
        if (
          this.business_unit.name === null ||
          this.business_unit.name === "" ||
          this.business_unit.weight === null ||
          this.business_unit.weight === "" ||
          this.business_unit.line_weight === null ||
          this.business_unit.line_weight === ""
        ) {
          this.checkErrors();
        } else {
          const response = await business_unit.update(
            business_unit_id,
            this.business_unit
          );
          if (response.status === 202) {
            // toaster.success(response.data.message , {position: 'top'});
            this.successModel = true;
            this.successMessage = response.data.message;
            setTimeout(() => {
              this.successModel = false;
              this.$router.push({ path: "/business-unit" });
            }, 1000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    Model,
  },
};
</script>