<template>
  <div class="user-table">
    <table class="table">
      <thead>
        <tr class="thead-cell">
          <th v-for="(th, idx) in thead" :key="idx">
            {{ th }}
          </th>
          <th>
            <div
              class="d-flex actions-container justify-content-end gap-3 align-items-center"
            >
              Actions
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="tbody-cell" v-for="td in tbody" :key="td.id">
          <td v-for="(key, idx) in keys" :key="idx">
            {{ td[key] }}
          </td>
          <td class="px-4">
            <div
              class="d-flex actions-container justify-content-end gap-3 align-items-center"
            >
              <div
                class="d-flex gap-1 align-items-center penDesign"
                @click="editHandler(td)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="vuesax_linear_edit"
                    data-name="vuesax/linear/edit"
                    transform="translate(-620 -252)"
                  >
                    <g id="edit">
                      <path
                        id="Vector"
                        d="M9,0H7C2,0,0,2,0,7v6c0,5,2,7,7,7h6c5,0,7-2,7-7V11"
                        transform="translate(622 254)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M8.991,1.271l-7.88,7.88a2.712,2.712,0,0,0-.66,1.32l-.43,3.01a1.424,1.424,0,0,0,1.7,1.7l3.01-.43a2.8,2.8,0,0,0,1.32-.66l7.88-7.88c1.36-1.36,2-2.94,0-4.94S10.351-.089,8.991,1.271Z"
                        transform="translate(627.049 253.749)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M0,0A7.144,7.144,0,0,0,4.94,4.94"
                        transform="translate(634.91 256.15)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(620 252)"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>
                <div class="action_text">Edit</div>
              </div>
              <div
                class="d-flex gap-1 align-items-center trashDesign"
                @click="deleteHandler(td)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                >
                  <g
                    id="vuesax_linear_trash"
                    data-name="vuesax/linear/trash"
                    transform="translate(-108 -188)"
                  >
                    <g id="trash">
                      <path
                        id="Vector"
                        d="M18,.5C14.67.17,11.32,0,7.98,0A59.068,59.068,0,0,0,2.04.3L0,.5"
                        transform="translate(111 193.48)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M0,2.97.22,1.66C.38.71.5,0,2.19,0H4.81C6.5,0,6.63.75,6.78,1.67L7,2.97"
                        transform="translate(116.5 190)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-3"
                        data-name="Vector"
                        d="M13.7,0l-.65,10.07c-.11,1.57-.2,2.79-2.99,2.79H3.64C.85,12.86.76,11.64.65,10.07L0,0"
                        transform="translate(113.15 197.14)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-4"
                        data-name="Vector"
                        d="M0,0H3.33"
                        transform="translate(118.33 204.5)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-5"
                        data-name="Vector"
                        d="M0,0H5"
                        transform="translate(117.5 200.5)"
                        fill="none"
                        stroke="#f47d1f"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1.5"
                      />
                      <path
                        id="Vector-6"
                        data-name="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(108 188)"
                        fill="none"
                        opacity="0"
                      />
                    </g>
                  </g>
                </svg>
                <div class="action_text">Delete</div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ConfirmationModal
    v-if="confirmModal"
    :message="confirmMessage"
    :confirmBtn="confirmBtnMsg"
    :cancleBtn="cancelBtnMsg"
    @close-modal="confirmModalHandler($event, this.model)"
  />
</template>

<script>
import ConfirmationModal from "./ConfirmationModal";
import manager from "../api-services/manager";
import cluster from "../api-services/cluster";
import { createToaster } from "@meforma/vue-toaster";
import business_unit from "../api-services/business_unit";

export default {
  name: "table-component",
  props: ["thead", "tbody", "name", "model", "keys", "updateUrl"],
  emits: ["update"],
  data() {
    return {
      confirmModal: false,
      confirmMessage: null,
      confirmBtnMsg: null,
      cancelBtnMsg: null,
      data: {},
    };
  },
  methods: {
    async editHandler(e) {
      if (this.model === "users") {
        this.$router.push({ path: `${this.updateUrl}/` + e.id });
      } else if (this.model === "business_units") {
        this.$router.push({ path: `${this.updateUrl}/` + e.id });
      } else if (this.model === "clusters") {
        this.$router.push({ path: `${this.updateUrl}/` + e.id });
      }
    },
    async deleteHandler(e) {
      this.confirmModal = true;
      this.confirmMessage =
        "Are you sure that you want to delete this " + this.name + "?";
      this.confirmBtnMsg = "Delete";
      this.cancelBtnMsg = "Cancel";
      this.data = e;
    },
    async confirmModalHandler(event, model) {
      if (model === "users") {
        if (event) {
          this.confirmModal = false;
          const response = await manager.delete(this.data.id);
          this.$emit("update", this.data);
          const toaster = createToaster();
          toaster.success(response.data.message, { position: "top" });
        } else {
          this.confirmModal = false;
        }
      } else if (model === "business_units") {
        if (event) {
          this.confirmModal = false;
          const response = await business_unit.delete(this.data.id);
          this.$emit("update", this.data);
          const toaster = createToaster();
          toaster.success(response.data.message, { position: "top" });
        } else {
          this.confirmModal = false;
        }
      } else if (model === "clusters") {
        if (event) {
          this.confirmModal = false;
          const response = await cluster.delete(this.data.id);
          this.$emit("update", this.data);
          const toaster = createToaster();
          toaster.success(response.data.message, { position: "top" });
        } else {
          this.confirmModal = false;
        }
      }
    },
  },
  components: {
    ConfirmationModal,
  },
};
</script>

<style scoped>
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 2rem;
  background-color: #eca715;
  font-weight: 500;
  border-radius: 5px;
  color: #13161c;
}
.user-table table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
thead {
  background-color: #3c3c3b !important;
  color: white;
  border-collapse: collapse;
  overflow: hidden;
}
thead tr td {
  padding: 1em !important ;
}
td,
th {
  border: solid 1px #dadada;
  border-style: solid none;
  padding: 1rem;
  vertical-align: middle;
  overflow: hidden;
}

td:first-child,
th:first-child {
  border-left-style: solid;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

td:last-child,
th:last-child {
  border-right-style: solid;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
th:last-child div {
  margin-right: 6rem;
}
tbody {
  background-color: #fff !important;
  color: #777777;
}
main {
  overflow: auto;
}

.trashDesign {
  cursor: pointer;
}
.trashDesign:hover {
  cursor: pointer;
  color: red;
}
.trashDesign:hover svg path {
  stroke: red;
}
.penDesign {
  cursor: pointer;
}
.penDesign:hover {
  color: #f47d1f;
}
.user-table {
  min-height: 70vh;
}
.tbody-cell > :first-child {
  padding: 1rem !important;
}
.thead-cell > :first-child {
  padding: 1rem !important;
}
.action_text {
  font-weight: 500;
}
</style>