import api from './api';

export default {
    createSession(){
        return api.get(`${process.env.VUE_APP_URL_PRO}/sanctum/csrf-cookie`);
    },
    login(params){
        return api.post(`${process.env.VUE_APP_URL}/login` , params);
    },
    logout(){
        return api.post(`${process.env.VUE_APP_URL}/logout`);
    }
}