import store from '../../store/store';
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster();

export default (to , from , next) => {
    if(store.getters['authenticated'] && store.getters['roles'] === 'market_impact_account'){
        if (from.path != "/market-impact-screen/") {
            next();
        }
        console.log("market_impact_account")
    }else{
        toaster.error(`You don't have permission to view market impact account data`, {position: 'top'});
        next({name: from.name});
    }
}