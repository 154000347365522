<template>
  <div class="cluster-item" v-for="target in cluster" :key="target.id">
    <p class="code" v-if="target.month === 1">January</p>
    <p class="code" v-else-if="target.month === 2">Febrauary</p>
    <p class="code" v-else-if="target.month === 3">March</p>
    <p class="code" v-else-if="target.month === 4">April</p>
    <p class="code" v-else-if="target.month === 5">May</p>
    <p class="code" v-else-if="target.month === 6">June</p>
    <p class="code" v-else-if="target.month === 7">July</p>
    <p class="code" v-else-if="target.month === 8">August</p>
    <p class="code" v-else-if="target.month === 9">September</p>
    <p class="code" v-else-if="target.month === 10">October</p>
    <p class="code" v-else-if="target.month === 11">November</p>
    <p class="code" v-else>December</p>
    <p class="details">
      <input
        class="w-100"
        type="number"
        placeholder="0.00"
        
        @change="checkInputValue($event, target.month)"
        v-model="target.target"
      />
    </p>
  </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";

export default {
  name: "target-component",
  props: ["cluster", "cluster_id", "role", "monthNow"],
  emits: ["changed", "errorInput"],
  data() {
    return {
      clusterTarget: {
        target: "",
        month: "",
        cluster_id: this.cluster_id,
        year: "",
      },
      errorInputChanged: false,
    };
  },
  mounted() {
    // console.log(this.role);
    // console.log(this.monthNow);
  },
  methods: {
    checkInputValue(e, month) {
      const toaster = createToaster();
      let currentKey = e.target.value;
      let re = new RegExp(/^[0-9]{1,8}(\.[0-9]{1,2})?$/);
      if (!re.test(currentKey)) {
        toaster.warning("invalid number format ex: (12345678.12)", {
          position: "top",
        });
        this.errorInputChanged = true;
        this.$emit("errorInput", this.errorInputChanged);
      } else {
        this.errorInputChanged = false;
        this.$emit("errorInput", this.errorInputChanged);
        this.clusterTarget.target = +e.target.value;
        this.clusterTarget.month = month;

        const d = new Date();
        this.clusterTarget.year = d.getFullYear();
        // this.monthNow = d.getMonth();

        this.$emit("changed", { ...this.clusterTarget });
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
}
.target-body-container {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 1rem;
  overflow: auto;
}
.target-body {
  /* margin-top: 2rem; */
  width: 100%;
  /* border: solid 1px #707070;; */
  /* min-height: 70vh; */
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* padding: 1rem; */
  overflow: auto;
}
.cluster-container {
  width: 32%;
}
.clusters {
  height: 25vh;
  overflow-y: auto;
  margin-bottom: 0.5rem;
}

.cluster-item {
  color: #737373;
  display: flex;
  flex-wrap: nowrap;
  background-color: #fff;
  margin-bottom: 2px;
}
.cluster-item:nth-child(even) {
   background-color: #EBEBEB;
}
.cluster-item p {
  margin: 0;
}
.code {
  width: 35%;
  margin-right: 2px !important;
  padding: 0.5rem 1rem;
  border-right: 1px solid white;
}
.cluster-item:nth-child(odd) .code{
  border-right: 1px solid #EBEBEB;
}
.details {
  width: 70%;
  padding: 0.5rem 0.25rem;
}
.details input {
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  color: #737373;
  padding-left: 1rem;
  text-align: center;
  font-weight: 400;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #737373;
  text-align: center;
  font-weight: 400;

}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #737373;
  text-align: center;
  font-weight: 400;

}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #737373;
  text-align: center;
  font-weight: 400;

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>