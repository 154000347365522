<template>
  <div class="modal-overlay" @click="$emit('close')">
    <div class="modal" ref="myBtn" @click="$emit('close')">
      <div class="modal__content">
        <div class="d-flex align-items-center gap-2 mb-4">
        <div v-if="confirmBtn === 'Logout'"><img src="./../assets/images/logout-modal.png" alt="logout-icon"></div>
        <div v-if="confirmBtn === 'Delete'"><img src="./../assets/images/delete-modal.png" alt="delete-icon"></div>
        <p class="cssMessage">{{ message }}</p>
        </div>
        <div class="d-flex gap-3 justify-content-end">
          <button class="confirm-btn" @click="confirmHandler">
            {{ confirmBtn }}
          </button>
          <button class="cancle-btn" @click="cancleHandler">
            {{ cancleBtn }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "confirmation-component",
  props: ["cancleBtn", "confirmBtn", "message"],
  data() {
    return {
      state: false,
    };
  },
  methods: {
    confirmHandler() {
      this.$emit("close-modal", true);
    },
    cancleHandler() {
      this.$emit("close-modal", false);
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #0e0e0e78;
  z-index: 100;
}

.modal {
  text-align: left;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: unset !important;
  background-color: #fff;
  border-radius: 20px;
  margin: auto;
  width: 40%;
  height: fit-content;
  padding: 2em;
}
.modal__content {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 22px;
  margin: 30px 0;
  color: #f47d1f;
  font-weight: 700;
}

button {
  width: 200px;
  height: 45px;
  color: white;
  font-size: 16px;
  border-radius: 25px;
  font-weight: 500;
  border: 1px solid #707070;
}

.confirm-btn {
  background-color: #3c3c3b;
}
.cancle-btn {
  background-color: transparent;
  color: #707070;
}
</style>