import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/Auth/Login';
import Wrapper from '@/views/Wrapper';
import Home from '@/views/Home';
import HomeAdmin from '@/views/HomeAdmin';
import UserIndex from '@/views/users/index';
import CreateUser from '@/views/users/createUser';
import EditUser from '@/views/users/editUser';
import MarketIndex from '@/views/market/index';
import CreateMarket from '@/views/market/createMarket';
import EditMarket from '@/views/market/editMarket';
import BusinessUnitIndex from '@/views/businessUnit/index';
import CreateBusinessUnit from '@/views/businessUnit/createBusinessUnit';
import EditBusinessUnit from '@/views/businessUnit/editBusinessUnit';
import TargetIndex from '@/views/target/index';
import ClusterIndex from '@/views/cluster/index';
import CreateCluster from '@/views/cluster/createCluster';
import EditCluster from '@/views/cluster/editCluster';
import SalesAccountPage from '@/views/salesAccount/index';
import SalesManagerAccount from '@/views/salesManagerAccount/index';
import MarketImpactAccount from '@/views/marketImpactMarket/index';
import CommercialScreen from '@/views/screens/commercialScreen';
import CommercialManagerScreen from '@/views/screens/commercialManagerScreen';
import MarketImpactScreen from '@/views/screens/marketImpactScreen';
import NotFoundPage from '@/components/NotFoundPage';

import middleware from '@/middleware';

const routes = [
  {
    path: '/login',
    name: 'login-page',
    component: Login,
    beforeEnter: middleware.guest
  },
  {
    path: '/',
    name: 'wrapper-page',
    component: Wrapper,
    beforeEnter: middleware.user,
    children: [
      {
        path: '/home',
        name: 'home-page',
        component: Home,
        beforeEnter: middleware.Manager
      },
      {
        path: '/users',
        name: 'users-index',
        component: UserIndex,
        beforeEnter: middleware.Manager
      },
      {
        path: '/create-user',
        name: 'create-user',
        component: CreateUser,
        beforeEnter: middleware.Manager
      },
      {
        path: '/edit-user/:id',
        name: 'edit-user',
        component: EditUser,
        beforeEnter: middleware.Manager
      },
      {
        path: '/business-unit',
        name: 'business-unit-index',
        component: BusinessUnitIndex,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/create-business-unit',
        name: 'create-business-unit',
        component: CreateBusinessUnit,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/edit-business-unit/:id',
        name: 'edit-business-unit',
        component: EditBusinessUnit,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/market',
        name: 'market-index',
        component: MarketIndex,
        beforeEnter: middleware.Manager
      },
      {
        path: '/create-market',
        name: 'create-market',
        component: CreateMarket,
        beforeEnter: middleware.Manager
      },
      {
        path: '/edit-market/:id',
        name: 'edit-market',
        component: EditMarket,
        beforeEnter: middleware.Manager
      },
      {
        path: '/cluster',
        name: 'cluster-index',
        component: ClusterIndex,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/create-cluster',
        name: 'create-cluster',
        component: CreateCluster,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/edit-cluster/:id',
        name: 'edit-cluster',
        component: EditCluster,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/target',
        name: 'target-index',
        component: TargetIndex,
        beforeEnter: middleware.managerFinanceRule
      },
      {
        path: '/admin',
        name: 'home-admin',
        component: HomeAdmin,
        beforeEnter: middleware.Finance
      },

      {
        path: '/sales-account',
        name: 'sales-account-page',
        component: SalesAccountPage,
        beforeEnter: middleware.SalesAccount
      },

      {
        path: '/sales-manager-account',
        name: 'sales-manager-account-page',
        component: SalesManagerAccount,
        beforeEnter: middleware.SalesManagerAccount
      },

      {
        path: '/market-impact-account',
        name: 'market-impact-account-page',
        component: MarketImpactAccount,
        beforeEnter: middleware.MarketImpactAccount
      },
      {
        path: '/commercial-screen',
        name: 'commercial-screen',
        component: CommercialScreen,
        query: { token: process.env.VUE_APP_TOKEN, bu: 'value' },
        beforeEnter: middleware.canViewCommericialScreen
      },
      {
        path: '/market-impact-screen',
        name: 'market-impact-screen',
        component: MarketImpactScreen,
        query: { token: process.env.VUE_APP_TOKEN, bu: 'value' }, // another query param added
        beforeEnter: middleware.canViewMarketImpactScreen
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: "error",
    component: NotFoundPage,
  }
  ,
  {
    path: '/commercial-manager-screen',
    name: 'commercial-manager-screen',
    component: CommercialManagerScreen,
    beforeEnter: middleware.Manager
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router;