<template>
  <div class="home">
    <form class="form">
      <h3>
        Welcome Back,
        <div>{{ managerName }}</div>
      </h3>
      <div class="controls-container d-flex gap-2">
        <div class="form-group">
          <label for="" class="mb-2"> Market Country </label>
          <select
            class="form-select"
            id="inlineFormSelectPref"
            v-model="market_id"
            @change="selectMarket">
            <option selected disabled value="null">Market Country</option>
            <option
              v-for="market in markets"
              :key="market.id"
              :value="market.id">
              {{ market.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="" class="mb-2"> Business Unit </label>
          <select
            class="form-select"
            :class="{ disabled: isDisabledOne }"
            id="inlineFormSelectPref"
            v-model="business_unit_id"
            @change="selectBusinessUnit">
            <option selected disabled value="null">Business Unit</option>
            <option
              v-for="business_unit in business_units"
              :key="business_unit.id"
              :value="business_unit.id">
              {{ business_unit.name }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="" class="mb-2"> Clusters </label>
          <select
            class="form-select"
            :class="{ disabled: isDisabledTwo }"
            id="inlineFormSelectPref"
            v-model="clusterEmail"
            @change="selectCluster">
            <option selected disabled value="null">Clusters</option>
            <option
              v-for="cluster in clusters"
              :key="cluster.id"
              :value="cluster.email">
              {{ cluster.name }}
            </option>
          </select>
        </div>
        <div class="d-flex align-items-end">
          <button
            :class="{ disabled: isDisabledThree }"
            class="btn filter-btn"
            @click.prevent="getDataCluster">
            Filter
          </button>
        </div>
      </div>
    </form>
    <div class="home-body">
      <div v-if="Object.keys(couesData).length" class="data-exist">
        <div
          class="cluster-container"
          v-for="(item, idx) in couesData"
          v-bind:key="idx">
          <div class="cluster-header">
            <h5 class="m-0" v-b-tooltip.hover :title="idx">
              {{ idx.slice(0, 30) + (idx.length > 30 ? "..." : "") }}
            </h5>
          </div>
          <div class="clusters">
            <div class="cluster-item" v-for="(data, idx2) in item" :key="idx2">
              <p class="details">{{ data.title }}</p>
              <p class="code">{{ decimalNumber(data.Opportunity) }} DHS</p>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5 no_data_container" v-else>
        <img src="./../assets/images/no-data.png" alt="no data available" />
        <h3>Apply Filters and Data Will be Shown Here.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import business_unit from "../api-services/business_unit";
import market from "../api-services/market";
import target from "../api-services/target";
import axios from "axios";
import store from "../store/store";

export default {
  name: "home-page",
  mounted() {
    this.$store.dispatch("activeTap", "home");
    this.getMarkets();
    this.getBusinessUnits();
    // this.dataFromLocalStorage = JSON.parse(localStorage.getItem('home-data'));
    this.managerName = this.$store.state.auth.user.user.name;
  },
  data() {
    return {
      markets: [],
      business_units: [],
      isDisabledOne: true,
      isDisabledTwo: true,
      isDisabledThree: true,
      market_id: null,
      business_unit_id: null,
      clusters: [],
      clusterEmail: null,
      couesData: {},
      managerName: null,
    };
  },
  methods: {
    async getMarkets() {
      const responseMarkets = await market.index();
      this.markets = responseMarkets.data.data;
    },
    async getBusinessUnits() {
      const responseBusinessUnits = await business_unit.all();
      this.business_units = responseBusinessUnits.data.data;
    },
    async selectMarket(e) {
      this.couesData = {};
      this.market_id = e.target.value;
      this.isDisabledOne = false;

      const response = await target.getClusters(
        this.market_id,
        this.business_unit_id
      );
      this.clusters = response.data.data;
    },
    async selectBusinessUnit(e) {
      this.couesData = {};
      this.business_unit_id = e.target.value;
      this.isDisabledTwo = false;
      const response = await target.getClusters(
        this.market_id,
        this.business_unit_id
      );
      this.clusters = response.data.data;
    },
    async selectCluster(e) {
      this.clusterEmail = e.target.value;
      this.isDisabledThree = false;
    },
    formatNumber(num) {
      return parseFloat(num).toFixed(2);
    },
    decimalNumber(num) {
      return parseInt(Math.round(num));
    },
    async getDataCluster() {
      this.isDisabledThree = true;
      const d = new Date();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      try {
        store.dispatch("setIsLoading", true);
        await axios
          .post(process.env.VUE_APP_COUES_URL + "/adminView", {
            email: this.clusterEmail,
            month: month,
            year: year,
            market: this.market_id,
          })
          .then((response) => {
            setTimeout(() => {
              if (response.data) {
                console.log(response.data);
                this.couesData = response.data;
                store.dispatch("setIsLoading", false);
                this.isDisabledThree = false;
              } else {
                store.dispatch("setIsLoading", false);
              }
            }, 2000);
          });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style scoped>
form h3 {
  font-weight: 800;
  font-size: 20px;
}
form h3 div {
  color: #f47d1f;
  display: inline;
  font-weight: 700;
}
.controls-container {
  background-color: #f47d1f;
  padding: 1em;
  border-radius: 8px;
}
.data-exist {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.no_data_container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3em;
  width: 100%;
}
.no_data_container h3 {
  font-size: 16px !important;
  font-weight: 500;
  color: #a8a6a6;
}
.disabled {
  pointer-events: none;
}
.form {
  /* color: #fff; */
}
.filter-btn {
  font-weight: 600;
  padding: 0.5rem 3rem;
  color: white !important;
  background-color: #3c3c3b !important;
  cursor: pointer;
  border-radius: 25px;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3c3c3b;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 16px;
  padding: 0.5em 1em;
}
.filter-btn:hover {
  width: 100%;
  background-color: #e29119;
  color: #fff;
}
.filter-btn:focus {
  width: 100%;
  background-color: #e29119;
  /* color: #fff; */
}
.filter-btn:active {
  width: 100%;
  background-color: #e29119;
  /* color: #fff; */
}
.form-group {
  width: 25%;
}
.form-group label {
  /* color: white; */
  font-size: 18px;
  font-weight: 700;
  padding-left: 1rem;
}
.home {
  /* overflow-y: auto; */
}
.home-body {
  margin-top: 2rem;
  width: 100%;
  min-height: 66vh;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: auto;
}
.cluster-container {
  width: 100%;
  margin-right: 0.5rem;
}
.clusters {
  max-height: 20vh;
  overflow-y: auto;
  margin-bottom: 0.5rem;
}
.cluster-header {
  background-color: #f4b345;
  color: #3c3c3b;
  padding: 0.5rem 0;
  border-radius: 10px;
  padding: 0.8rem 5%;
  margin-bottom: 0.3rem;
}
.cluster-header h5 {
  font-weight: 700;
  font-size: 18px;
}
.cluster-item {
  display: flex;
  border-radius: 10px;
  border: 1px solid #dadada;
  padding: 0.5em 5%;
  margin-bottom: 5px;
  justify-content: space-between;
}
.cluster-item p {
  margin: 0;
  color: #737373;
}
.code {
  width: 10%;
  margin-right: 2px !important;
  padding: 0.25rem;
}
.details {
  width: 25%;
  padding: 0.25rem;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
</style>
