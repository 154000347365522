import api from './api';

export default {
    index(page=1){
        return api.get(`${process.env.VUE_APP_URL}/markets/get/all` , {params: {page:page}});
    },
    indexWithoutLoading(page=1){
        return api.get(`${process.env.VUE_APP_URL}/markets/get/all` , {params: {page:page}} , { 'headers': { 'loader': true } });
    },
    generate(){
        return api.get(`${process.env.VUE_APP_URL}/markets/generate`);
    },
    getMarketByName(name){
        return api.get(`${process.env.VUE_APP_URL}/get/market/${name}` , { 'headers': { 'loader': true } });
    }
}