<template>
  <div class="edit-cluster">
    <form action="">
      <div class="header d-flex justify-content-between align-items-center">
        <div class="title">
          <h3>Edit Cluster</h3>
        </div>
        <div class="">
          <div class="d-flex align-items-end">
            <button
              @click.prevent="updateCluster"
              class="create-button"
              :class="[{ notAllowed: errorBtnSave }]"
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <div class="form-body">
        <div class="row mb-2">
          <div class="input-container">
            <label class="mb-2">Cluster Name</label>
            <input
              type="text"
              class="form-control"
              placeholder="Cluster Name"
              v-model="cluster.name"
              @input="checkName"
            />
            <span class="text-danger" v-if="errorName">*Required</span>
          </div>
          <div class="input-container">
            <label class="mb-2">Market Country</label>
            <select
              class="form-select"
              v-model="cluster.market_id"
              @change="checkMarket"
            >
              <option selected>Market Country</option>
              <option
                v-for="market in markets"
                :key="market.id"
                :value="market.id"
              >
                {{ market.name }}
              </option>
            </select>
            <span class="text-danger" v-if="errorMarket">*Required</span>
          </div>
        </div>
        <div class="row mb-2">
          <div class="input-container">
            <label class="mb-2">Business Unit</label>
            <select
              class="form-select"
              v-model="cluster.business_unit_id"
              @change="checkBusinessUnit"
            >
              <option selected>Business Unit</option>
              <option
                v-for="business_unit in business_units"
                :key="business_unit.id"
                :value="business_unit.id"
              >
                {{ business_unit.name }}
              </option>
            </select>
            <span class="text-danger" v-if="errorBusinessUnit">*Required</span>
          </div>
          <div class="input-container">
            <label class="mb-2">Email</label>
            <input
              type="email"
              class="form-control"
              placeholder="Cluster Email"
              v-model="cluster.email"
              @input="checkEmail"
            />
            <span class="text-danger" v-if="errorEmail">*Required</span>
          </div>
        </div>
      </div>
    </form>
  </div>
  <Model v-if="successModel" :message="successMessage" />
</template>

<style scoped>
::placeholder {
  color: #fff !important;
}
.allowed {
  cursor: pointer;
}

.notAllowed {
  cursor: not-allowed;
}
.title {
  color: #fff;
}
.create-button {
  padding: 0.5rem 4rem;
  background-color: #f47d1f;
  font-weight: 500;
  border-radius: 25px;
  color: #ffffff;
  border: none;
}
.form-body {
  margin-top: 2rem;
  width: 100%;
  border: solid 1px #707070;
  min-height: 70vh;
  border-radius: 5px;
  /* display: flex; */
  /* flex-wrap: wrap;
        justify-content: space-around; */
  padding: 1rem;
  overflow: auto;
  color: #fff;
}
.form-body .input-container {
  width: 40%;
}
.form-body .input-container label {
  color: #707070;
  font-weight: 500;
  margin-left: 12px;
}
option {
  background-color: #fff !important;
  color: #13161c !important;
}
.form-select {
  background-color: white;
  position: relative;
  color: #3C3C3B;
  font-weight: 300;
  cursor: pointer;
  border-radius: 25px;
  border-color: #707070 !important;
  font-size: 14px;
  padding: 0.5em 1em;
}
.form-control {
  background-color: transparent;
  border: 1px solid #707070;
  border-radius: 25px;
}
</style>

<script>
import { createToaster } from "@meforma/vue-toaster";
import cluster from "../../api-services/cluster";
import market from "../../api-services/market";
import business_unit from "../../api-services/business_unit";
// import store from "../../store/store";
import Model from "../../components/Model.vue";

export default {
  name: "edit-cluster",
  async mounted() {
    const clusterId = this.$route.params.id;
    this.getMarkets();
    this.getBusinessUnits();
    this.getClusterData();
    this.$store.dispatch("activeTap", "clusters");
    await this.$store.dispatch("currentRoute", "edit-cluster/" + clusterId);
  },
  data() {
    return {
      successModel: false,
      successMessage: null,
      business_units: [],
      markets: [],
      cluster: {
        name: null,
        email: null,
        market_id: null,
        business_unit_id: null,
      },
      errorName: false,
      errorEmail: false,
      errorMarket: false,
      errorBusinessUnit: false,
      errorBtnSave: false,
    };
  },
  methods: {
    async getMarkets() {
      try {
        const response = await market.indexWithoutLoading();
        this.markets = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getBusinessUnits() {
      try {
        const response = await business_unit.allWithoutLoader();
        this.business_units = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
    async getClusterData() {
      const clusterId = this.$route.params.id;
      const response = await cluster.show(clusterId);
      this.cluster = response.data.data;
    },
    checkErrors() {
      this.errorName = false;
      this.errorEmail = false;
      this.errorMarket = false;
      this.errorBusinessUnit = false;

      if (this.cluster.name === null || this.cluster.name === "") {
        this.errorName = true;
      }

      if (this.cluster.email === null || this.cluster.email === "") {
        this.errorEmail = true;
      }

      if (this.cluster.market_id === null) {
        this.errorMarket = true;
      }

      if (this.cluster.business_unit_id === null) {
        this.errorBusinessUnit = true;
      }

      this.errorBtnSave =
        this.errorName ||
        this.errorEmail ||
        this.errorMarket ||
        this.errorBusinessUnit;
    },
    checkName() {
      this.checkErrors();
    },
    checkEmail() {
      this.checkErrors();
    },
    checkMarket() {
      this.checkErrors();
    },
    checkBusinessUnit() {
      this.checkErrors();
    },
    async updateCluster() {
      try {
        const toaster = createToaster();
        const clusterId = this.$route.params.id;
        if (
          this.cluster.name === null ||
          this.cluster.name === "" ||
          this.cluster.email === null ||
          this.cluster.email === "" ||
          this.cluster.market_id === null ||
          this.cluster.business_unit_id === null
        ) {
          this.checkErrors();
        } else {
          const response = await cluster.update(clusterId, this.cluster);
          if (response.status === 202) {
            this.successModel = true;
            this.successMessage = response.data.message;
            setTimeout(() => {
              this.successModel = false;
              this.$router.push({ name: "cluster-index" });
            }, 1000);
            // toaster.success(response.data.message , {position: 'top'});
            // setTimeout(() => {
            // this.$router.push({name: 'cluster-index'});
            // }, 1000);
          } else {
            toaster.warning(`something wrong`, { position: "top" });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  components: {
    Model,
  },
};
</script>