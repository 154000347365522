import { createApp } from "vue";
import App from "./App.vue";
import store from "./store/store";
import router from "./router/router";
import "bootstrap/dist/css/bootstrap.css";
import Toaster from "@meforma/vue-toaster";

import BootstrapVue from "../node_modules/bootstrap-vue-3";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen,
  faTrash,
  faChevronDown,
  faRightFromBracket,
  faHouse,
  faUser,
  faBriefcase,
  faLocationDot,
  faHandshake,
  faEye,
  faEyeSlash,
  faBook,
  faTriangleExclamation,
  faCodeBranch
} from "@fortawesome/free-solid-svg-icons";

//comment here again

library.add(
  faPen,
  faTrash,
  faChevronDown,
  faRightFromBracket,
  faHouse,
  faUser,
  faBriefcase,
  faLocationDot,
  faHandshake,
  faBook,
  faEye,
  faEyeSlash,
  faTriangleExclamation,
  faCodeBranch
);

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

let app = createApp(App);
app.config.globalProperties.$APP_URL = "http://127.0.0.1:8000/api";

app
  .use(store)
  .use(router)
  .use(BootstrapVue)
  .use(Toaster)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
